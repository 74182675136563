import { Close, PlayCircle } from "@mui/icons-material";
import React from "react";
import ReactModal from "react-modal";
import SelectField from "../../components/SelectField";
import { useQuery } from "@tanstack/react-query";
import HttpRequest from "../../functions/HttpRequest";
import SwalNotification from "../../functions/SwalNotification";
import is_null from "../../functions/is_null";

const customStyles = {
  overlay: {
    zIndex: 1000,
  },
  content: {
    top: "50%",
    left: "50%",
    minWidth: "95vw",
    right: "auto",
    minHeight: "50vh",
    zIndex: 1001,
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

const StartNewAccreditation = ({ row }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [selfStudyForm, setSelfStudyForm] = React.useState(true);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [sSF, setSSF] = React.useState(row?.SelfStudyForm);
  const { data, isLoading } = useQuery({
    queryKey: ["StartExerciseType"],
    queryFn: async () => {
      const req = await HttpRequest(`/nuc_exercise_type`, {
        method: "GET",
      });
      return req;
    },
  });
  const _submitForm = async () => {
    try {
      setIsSubmitting(true);
      if (is_null(sSF)) {
        throw new Error("Please fill the form properly");
      }
      const req = await HttpRequest(`/nuc_programmes/ssf`, {
        body: JSON.stringify({ programmeID: row?._id, exerciseID: sSF }),
        method: "PUT",
      });
      SwalNotification(req?.type, req?.message, "CREATE UNIVERSITY");
      setIsSubmitting(false);
    } catch ({ message }) {
      SwalNotification("error", message, "System Error");
      setIsSubmitting(false);
    }
  };
  return (
    <>
      <ReactModal style={customStyles} isOpen={isOpen} contentLabel="Example Modal">
        <div className="grid grid-cols-2 gap-4 gap-y-10 py-2 uppercase">
          <h2>
            {row.name} | {`${row?.departmentID?.collegeID?.institutionID?.name}`}
          </h2>
          <button onClick={() => setIsOpen(false)} className="text-right" style={{ color: "red" }}>
            <Close color="red" />
          </button>
        </div>
        <div className="p-4 md:p-5 space-y-4">
          <hr />
          {isLoading ? (
            "Please wait system loading..."
          ) : (
            <>
              <div className={`grid grid-cols-${selfStudyForm ? "1" : "2"} gap-4 gap-y-10 py-2`}>
                <h4>Start Accreditation Process</h4>
                <p className="justify ">Upon completing this step, the university will gain access to the self-study form for the program</p>
                <SelectField
                  label={"Pick a self study form"}
                  options={[
                    { label: "Use program's self study form", value: true },
                    { label: "Assign self study form for this accreditation now", value: false },
                  ]}
                  setValues={(e) => {
                    if (e.value) {
                      setSSF(row?.SelfStudyForm);
                    } else {
                      setSSF(null);
                    }
                    setSelfStudyForm(e.value);
                  }}
                />
                {!selfStudyForm && (
                  <SelectField
                    name={"university"}
                    label={"Pick a exercise type"}
                    options={data?.map((r) => ({ value: r?._id, label: r?.title }))}
                    setValues={(e) => setSSF(e.value)}
                  />
                )}
              </div>
              <button
                type="submit"
                disabled={isSubmitting}
                onClick={_submitForm}
                className="w-full bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded-lg focus:outline-none focus:bg-green-600"
              >
                {isSubmitting ? "Starting Accreditation..." : "Start Accreditation"}
              </button>
            </>
          )}
        </div>
      </ReactModal>
      <div onClick={() => setIsOpen(true)} aria-label="approve" className="cursor-pointer" color="success" style={{ cursor: "pointer" }}>
        <PlayCircle className="max-w-[35px] color-green[400]" color="green" />
      </div>
    </>
  );
};

export default StartNewAccreditation;
