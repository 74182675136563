import React from "react";

const InputField = (props) => {
  return (
    <div className="mb-4">
      <label htmlFor="name" className="block font-bold text-lg text-gray-800 mb-2">
        {props.label}
      </label>
      <div className="flex items-center border border-gray-300 rounded">
        <input {...props} className="w-full px-3 py-2 focus:outline-none focus:border-green-500" />
      </div>
    </div>
  );
};

export default InputField;
