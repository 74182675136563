import React from "react";
import { Outlet } from "react-router-dom";
// import { axiosget } from "./httprequest";
import NavigationBar from "./NavigationBar";
import Footers from "./Footers";

const AccountLayout = () => {
  return (
    <div className="container-scroller">
      <NavigationBar />
      <div className="container-fluid page-body-wrapper min-h-12">
        <Outlet />
      </div>
      <Footers />
    </div>
  );
};

export default AccountLayout;
