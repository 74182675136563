import React from "react";
import { MaterialReactTable } from "material-react-table";
import ClipLoader from "react-spinners/ClipLoader";
import SelectField from "../../components/SelectField";
import HttpRequest from "../../functions/HttpRequest";
import SwalNotification from "../../functions/SwalNotification";
import is_null from "../../functions/is_null";
import ActivateItem from "../../components/ActivateItem";

const ViewColleges = () => {
  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      setSubmitting(true);
      setTimeout(() => {
        setSubmitting(false);
      }, 200);
    } catch (error) {
      SwalNotification("error", "An error occurred. Please try again.", "ERROR ENCOUNTERED");
    } finally {
      setSubmitting(false);
    }
  };
  const [loading, setloading] = React.useState(true);
  const [selected, setselected] = React.useState(null);
  const [dataset, setdataset] = React.useState(null);
  const [college, setcollege] = React.useState(null);

  React.useEffect(() => {
    (async () => {
      try {
        setloading(true);
        const req = await HttpRequest(`/nuc_institutions/actives`, {
          method: "GET",
        });
        if (req.type) {
          SwalNotification(req.type, req.message, "CREATE UNIVERSITY");
          return;
        }
        const op = req?.map((r) => ({ value: r?._id, label: r?.name }));
        setdataset(op);
      } catch ({ message }) {
        SwalNotification("error", message, "System Error");
        setloading(false);
      }
    })();
  }, []);

  React.useEffect(() => {
    if (!is_null(selected)) {
      (async () => {
        try {
          setloading(true);
          console.log(selected);
          const req = await HttpRequest(`/nuc_colleges/university/${selected.value}`, {
            method: "GET",
          });
          if (req.type) {
            SwalNotification(req.type, req.message, "VIEW COLLEGE");
            return;
          }
          setcollege(req);
          setloading(false);
        } catch ({ message }) {
          SwalNotification("error", message, "System Error");
          setloading(false);
        }
      })();
    }
  }, [selected]);
  const columns = [
    {
      accessorFn: (r) => `${r.institutionID.name}`,
      header: "University Name",
    },
    {
      accessorKey: "name",
      header: "Faculty/College/School",
    },

    {
      accessorKey: "description",
      header: "Description",
    },
    {
      accessorFn: (row) => <ActivateItem row={row} link="nuc_colleges/active" />,
      header: "Status",
    },
    {
      accessorKey: "updatedAt",
      header: "Date & Time",
    },
  ];
  return (
    <div className="mx-auto max-w-7xl px-8 pt-4">
      <h2 className="text-4xl my-5 uppercase">View Colleges/Faculties/Schools</h2>
      <div className="grid grid-cols-1 gap-x-8 gap-y-10 py-2">
        <div className="grid grid-cols-1 gap-x-8 gap-y-10 py-2">
          <SelectField name={"university"} label={"Pick university"} options={dataset} setValues={setselected} />
        </div>
        {selected && (
          <>
            {loading ? (
              <ClipLoader size={35} color={"#36D7B7"} />
            ) : (
              <div className="TheTable">
                <MaterialReactTable columns={columns} data={college} enableRowNumbers initialState={{ density: "compact" }} />
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default ViewColleges;
