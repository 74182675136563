const loginUser = async (token) => {
  try {
    const { user, token: data } = token;
    localStorage.setItem("authToken", data);
    localStorage.setItem("systemUser", JSON.stringify(user));
    return true;
  } catch ({ message }) {
    return false;
  }
};

const logoutUser = async (token) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      localStorage.clear();
      resolve(true);
    }, 500);
  });
};

export { loginUser, logoutUser };
