import React from "react";
import { MaterialReactTable } from "material-react-table";
import ClipLoader from "react-spinners/ClipLoader";
import { useQuery } from "@tanstack/react-query";
import HttpRequest from "../../functions/HttpRequest";
import ReaccreditationSSF from "../../components/ReaccreditationSSF";

const ReAccredicationExercise = () => {
  const { data, isLoading } = useQuery({
    queryKey: ["ListofProgramme"],
    queryFn: async () => {
      const req = await HttpRequest(`/nuc_programmes/ssf/reaccreditate`, {
        method: "GET",
      });
      return req;
    },
  });
  const columns = [
    {
      accessorFn: (row) => `${row?.departmentID?.collegeID?.institutionID?.name}`,
      header: "University",
    },
    {
      accessorFn: (row) => `${row?.departmentID?.collegeID?.name}`,
      header: "College/Faculty",
    },
    {
      accessorFn: (row) => `${row?.departmentID?.name}`,
      header: "Department",
    },
    {
      accessorFn: (row) => `${row?.name}`,
      header: "Programme",
    },
    {
      accessorKey: "updatedAt",
      header: "Date & Time",
    },
    {
      accessorFn: (row) => <ReaccreditationSSF row={row} />,
      header: "Assign SSF",
    },
  ];

  return (
    <div className="mx-auto max-w-7xl px-8 pt-4">
      <h2 className="text-4xl my-5 uppercase">Start ReAccredication Exercise</h2>
      <div className="grid grid-cols-1 gap-x-8 gap-y-10 py-2">
        <div className="grid grid-cols-1 gap-x-8 gap-y-10 py-2"></div>
        <>
          {isLoading ? (
            <ClipLoader size={35} color={"#36D7B7"} />
          ) : (
            <div className="TheTable">
              <MaterialReactTable columns={columns} data={data} enableRowNumbers initialState={{ density: "compact" }} />
            </div>
          )}
        </>
      </div>
    </div>
  );
};

export default ReAccredicationExercise;
