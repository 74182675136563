import url from "./url";
import Cookies from "js-cookie";

const HttpRequest = async (link, head) => {
	try {
		// const
		const headers = {
			...head,
			credentials: "include",
			headers: {
				"Content-type": "application/json",
				nuc_staff: Cookies.get("Set-Cookie"),
			},
		};
		const req = await fetch(`${url}${link}`, headers);
		const res = await req.json();
		if (
			res.type === "error" &&
			String(res.message).includes("This session has expired")
		) {
			throw new Error(res.message);
		}
		return res;
	} catch ({ message }) {
		return { type: "error", message };
	}
};

export default HttpRequest;
