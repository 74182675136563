import CreateQuestion from "./CreateQuestion";
import UpdateQuestion from "./UpdateQuestion";
import DeleteQuestion from "./DeleteQuestion";
import ViewQuestion from "./ViewQuestion";

export default {
  CreateQuestion,
  UpdateQuestion,
  DeleteQuestion,
  ViewQuestion,
};
