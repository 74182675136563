import { useQuery } from "@tanstack/react-query";
import HttpRequest from "../functions/HttpRequest";

const useFetchInstitutionsActive = () => {
  const { data, isLoading, isError } = useQuery({
    queryKey: ["institutionActive"],
    queryFn: async () => {
      const req = await HttpRequest(`/nuc_institutions/actives`, {
        method: "GET",
      });
      return req;
    },
  });

  return { data, isLoading, isError };
};

export default useFetchInstitutionsActive;
