import CreateSemester from "./CreateSemester";
import DeleteSemester from "./DeleteSemester";
import UpdateSemester from "./UpdateSemester";
import ViewSemesters from "./ViewSemester";

export default {
  CreateSemester,
  DeleteSemester,
  UpdateSemester,
  ViewSemesters,
};
