import LoginForm from "./LoginForm";
import Footer from "./Footer";
import Header from "./Header";

const LoginPage = () => {
  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="w-full md:w-4/5 lg:w-3/5 xl:w-1/3 bg-white rounded-lg shadow-lg p-8">
        <Header />
        <div className="my-4 border-b-2 border-gray-300"></div>
        <LoginForm />
        <div className="my-4 border-b-2 border-gray-300"></div>
        <Footer />
      </div>
    </div>
  );
};

export default LoginPage;
