import React from "react";
import AccreditationChart from "./AccreditationChart";
import CurrentMonth from "./CurrentMonth";
import ContextAPI from "../../functions/ContextAPI";

const DashboardPage = () => {
  const context = React.useContext(ContextAPI);
  return (
    <div>
      <div className="mx-auto max-w-7xl px-8 pt-4">
        <h2 className="text-4xl my-5">
          Welcome {context?.values?.user?.firstname}...
        </h2>
        <div className="grid grid-cols-2 gap-x-8 gap-y-10 py-2">
          <div>
            <CurrentMonth
              desc={`Recently Completed`}
              number={60}
              percent={"45%"}
            />
          </div>
          <div>
            <CurrentMonth
              desc={`Accrediation On-Progress`}
              number={47}
              percent={"30%"}
            />
          </div>
        </div>
        <div className="grid grid-cols-1 gap-x-8 gap-y-10 py-2">
          <div style={{ zIndex: -10 }}>
            <AccreditationChart />
          </div>
        </div>
        <div className="grid grid-cols-1 gap-x-8 gap-y-10 py-2">
          <div>
            <CurrentMonth
              desc={`Pending Accrediations 2024`}
              number={35}
              percent={"25%"}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardPage;
