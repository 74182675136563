import React from "react";
import ClipLoader from "react-spinners/ClipLoader";
import SelectField from "../../components/SelectField";
import is_null from "../../functions/is_null";
import HttpRequest from "../../functions/HttpRequest";
import SwalNotification from "../../functions/SwalNotification";

const DeleteExerciseType = () => {
  const [isSubmitting, setSubmitting] = React.useState(false);
  const [isOptions, setOptions] = React.useState(null);
  const [isSectionOptions, setSectionOptions] = React.useState(null);
  const [isExercise, setExercise] = React.useState(null);
  const [isSection, setSection] = React.useState(null);
  const [isPickedExercise, setPickedExercise] = React.useState(null);
  const [loading, setloading] = React.useState(false);

  React.useEffect(() => {
    (async () => {
      try {
        setloading(true);
        const req = await HttpRequest(`/nuc_exercise_type`, {
          method: "GET",
        });
        if (req.type) {
          SwalNotification(req.type, req.message, "CREATE UNIVERSITY");
          return;
        }
        const op = req?.map((r) => ({ value: r?._id, label: r?.title }));
        setOptions(op);
        setExercise(req);
        setloading(false);
      } catch ({ message }) {
        SwalNotification("error", message, "System Error");
        setloading(false);
      }
    })();
  }, []);
  React.useEffect(() => {
    if (!is_null(isPickedExercise)) {
      (async () => {
        try {
          const req = await HttpRequest(`/nuc_sections/execise/${isPickedExercise._id}`, {
            method: "GET",
          });
          if (!is_null(req?.type)) {
            SwalNotification(req.type, req.message, "CREATE UNIVERSITY");
            return;
          }
          const op = req?.map((r) => ({ value: r?._id, label: r?.title }));
          setSectionOptions(op);
          setSection(req);
        } catch ({ message }) {
          SwalNotification("error", message, "System Error");
          setloading(false);
        }
      })();
    }
  }, [isPickedExercise]);
  const handleSubmit = async (e) => {
    try {
      setSubmitting(true);
      e.preventDefault();
      const fd = new FormData(e.target);
      const selected = fd.get("selected");
      if (is_null(selected)) {
        throw new Error("Please fill the form properly");
      }
      const req = await HttpRequest(`/nuc_sections/${selected}`, {
        method: "DELETE",
      });
      SwalNotification(req?.type, req?.message, "CREATE UNIVERSITY");
      setSubmitting(false);
    } catch ({ message }) {
      SwalNotification("error", message, "CREATE UNIVERSITY");
      setSubmitting(false);
    }
  };
  return (
    <div className="mx-auto max-w-7xl px-8 pt-4">
      <h2 className="text-4xl my-5 uppercase">Delete Question Section...</h2>
      <div className="grid grid-cols-1 gap-x-8 gap-y-10 py-2">
        <form onSubmit={handleSubmit}>
          {loading ? (
            <div>Loading...</div>
          ) : (
            <div className="grid grid-cols-1 gap-x-8 gap-y-10 py-2">
              <SelectField
                name={"exerciseType"}
                label={"Pick a exercise type"}
                options={isOptions}
                setValues={(r) => {
                  const { value } = r;
                  const filter = isExercise?.filter((f) => f._id === value).shift();
                  setPickedExercise(filter);
                }}
              />
            </div>
          )}
          {isSection && <SelectField name={"selected"} label={"Pick a section"} options={isSectionOptions} />}
          <div className="flex items-center justify-center mb-4">{isSubmitting && <ClipLoader size={35} color={"#36D7B7"} />}</div>
          <button
            type="submit"
            disabled={isSubmitting}
            className="w-full bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded-lg focus:outline-none focus:bg-green-600"
          >
            {isSubmitting ? "Deleting Question Section..." : "Delete Question Section"}
          </button>
        </form>
      </div>
    </div>
  );
};

export default DeleteExerciseType;
