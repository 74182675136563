import React from "react";
import ClipLoader from "react-spinners/ClipLoader";
import SelectField from "../../components/SelectField";
import HttpRequest from "../../functions/HttpRequest";
import SwalNotification from "../../functions/SwalNotification";
import is_null from "../../functions/is_null";

const DeleteProgramme = () => {
  const [pickedCollege, setpickedCollege] = React.useState(null);
  const [pickedDepartment, setpickedDepartment] = React.useState(null);
  const [picked, setpicked] = React.useState(null);
  const [collegeList, setcollegeList] = React.useState(null);
  const [loading, setloading] = React.useState(false);
  const [submitting, setSubmitting] = React.useState(false);
  const [data, setdata] = React.useState(null);
  const [dataCollge, setdataCollge] = React.useState(null);
  const [dataDepartment, setdataDepartment] = React.useState(null);
  const [dataProgramme, setdataProgramme] = React.useState(null);
  const [departmentOption, setDepartmentOption] = React.useState(null);
  const [programmeOptions, setProgrammeOptions] = React.useState(null);
  React.useEffect(() => {
    (async () => {
      try {
        setloading(true);
        const req = await HttpRequest(`/nuc_institutions/actives`, {
          method: "GET",
        });
        if (req.type) {
          SwalNotification(req.type, req.message, "CREATE UNIVERSITY");
          return;
        }
        const op = req?.map((r) => ({ value: r?._id, label: r?.name }));
        setdata(op);
        setloading(false);
      } catch ({ message }) {
        SwalNotification("error", message, "System Error");
        setloading(false);
      }
    })();
  }, []);
  React.useEffect(() => {
    if (!is_null(picked)) {
      (async () => {
        try {
          const req = await HttpRequest(`/nuc_colleges/university/${picked.value}`, {
            method: "GET",
          });
          if (req.type) {
            SwalNotification(req.type, req.message, "CREATE UNIVERSITY");
            return;
          }
          const op = req?.map((r) => ({ value: r?._id, label: r?.name }));
          setcollegeList(op);
          setdataCollge(req);
        } catch ({ message }) {
          SwalNotification("error", message, "System Error");
          setloading(false);
        }
      })();
    }
  }, [picked]);
  React.useEffect(() => {
    if (!is_null(pickedCollege)) {
      (async () => {
        try {
          const req = await HttpRequest(`/nuc_colleges/departments/${pickedCollege._id}`, {
            method: "GET",
          });
          if (req.type) {
            SwalNotification(req.type, req.message, "CREATE UNIVERSITY");
            return;
          }
          const op = req?.map((r) => ({ value: r?._id, label: r?.name }));
          setDepartmentOption(op);
          setdataDepartment(req);
        } catch ({ message }) {
          SwalNotification("error", message, "System Error");
        }
      })();
    }
  }, [pickedCollege]);
  React.useEffect(() => {
    if (!is_null(pickedDepartment)) {
      (async () => {
        try {
          const req = await HttpRequest(`/nuc_departments/programme/${pickedDepartment._id}`, {
            method: "GET",
          });
          if (req.type) {
            SwalNotification(req.type, req.message, "CREATE UNIVERSITY");
            return;
          }
          const op = req?.map((r) => ({ value: r?._id, label: r?.name }));
          setProgrammeOptions(op);
          setdataProgramme(req);
        } catch ({ message }) {
          SwalNotification("error", message, "System Error");
        }
      })();
    }
  }, [pickedDepartment]);
  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      setSubmitting(true);
      const fd = new FormData(e.target);
      const programme = fd.get("programme");
      if (is_null(programme)) {
        throw new Error("Please fill the form properly");
      }
      const req = await HttpRequest(`/nuc_programmes/${programme}`, {
        method: "DELETE",
      });
      SwalNotification(req.type, req.message, "System Error");
      setSubmitting(false);
    } catch ({ message }) {
      console.log(message);
      SwalNotification("error", message, "System Error");
      setSubmitting(false);
    }
  };
  return (
    <div className="mx-auto max-w-7xl px-8 pt-4">
      <h2 className="text-4xl my-5 uppercase">Delete Programme...</h2>
      <div className="grid grid-cols-1 gap-x-8 gap-y-10 py-2">
        <form onSubmit={handleSubmit}>
          {loading ? (
            <div>Loading...</div>
          ) : (
            <>
              <SelectField
                name={"university"}
                label={"Pick a university"}
                options={data}
                setValues={(r) => {
                  const { value } = r;
                  const filter = data?.filter((f) => f.value === value).shift();
                  setpicked(filter);
                }}
              />
              {collegeList && (
                <SelectField
                  name={"college"}
                  label={"Pick a college"}
                  options={collegeList}
                  setValues={(r) => {
                    const { value } = r;
                    const filter = dataCollge?.filter((f) => f._id === value).shift();
                    setpickedCollege(filter);
                  }}
                />
              )}
              {dataDepartment && (
                <SelectField
                  name={"department"}
                  label={"Pick a department"}
                  options={departmentOption}
                  setValues={(r) => {
                    const { value } = r;
                    const filter = dataDepartment?.filter((f) => f._id === value).shift();
                    setpickedDepartment(filter);
                  }}
                />
              )}
              {dataProgramme && <SelectField name={"programme"} label={"Pick a programme"} options={programmeOptions} />}
            </>
          )}
          <div className="flex items-center justify-center mb-4">{submitting && <ClipLoader size={35} color={"#36D7B7"} />}</div>
          <button
            type="submit"
            disabled={submitting}
            className="w-full bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded-lg focus:outline-none focus:bg-green-600"
          >
            {submitting ? "Deleting Programme..." : "Delete Programme"}
          </button>
        </form>
      </div>
    </div>
  );
};

export default DeleteProgramme;
