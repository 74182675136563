import { useQuery } from "@tanstack/react-query";
import React from "react";
import HttpRequest from "../../functions/HttpRequest";
import { MaterialReactTable } from "material-react-table";
import ViewSSFAnswersBtn from "../../components/ViewSSFAnswersBtn";

const ViewSSFAnswers = () => {
  const { data, isLoading } = useQuery({
    queryKey: ["ListofSSFCompleted"],
    queryFn: async () => {
      const req = await HttpRequest(`/nuc_programmes/ssf/submitted`, {
        method: "GET",
      });
      return req;
    },
  });
  const columns = [
    {
      accessorFn: (row) => `${row?.institutionID?.name}`,
      header: "University",
    },
    {
      accessorFn: (row) => `${row?.collegeID?.name}`,
      header: "College/Faculty",
    },
    {
      accessorFn: (row) => `${row?.departmentID?.name}`,
      header: "Department",
    },
    {
      accessorFn: (row) => `${row?.programmeID?.name}`,
      header: "Programme",
    },
    {
      accessorFn: (row) => `${row?.exerciseID?.title}`,
      header: "Assigned SSF",
    },

    {
      accessorFn: (row) => <ViewSSFAnswersBtn row={row} />,
      header: "View SSF Answers",
    },
  ];
  return (
    <div className="mx-auto max-w-7xl px-8 pt-4">
      <h2 className="text-4xl my-5 uppercase">View Self Study Form Answers...</h2>
      <div className="grid grid-cols-1 gap-x-8 gap-y-10 py-2">
        {isLoading ? (
          <div>Please wait system loading...</div>
        ) : (
          <div className="TheTable">
            <MaterialReactTable columns={columns} data={data} enableRowNumbers initialState={{ density: "comfortable" }} />
          </div>
        )}
      </div>
    </div>
  );
};

export default ViewSSFAnswers;
