import { ViewArray, Close } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import React from "react";
import ReactModal from "react-modal";
import HttpRequest from "../functions/HttpRequest";

const customStyles = {
  overlay: {
    zIndex: 1000,
  },
  content: {
    top: "50%",
    left: "50%",
    minWidth: "70%",
    right: "auto",
    zIndex: 1001,
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

const ViewSSFAnswersBtn = ({ row }) => {
  const [IsOpen, SetIsOpen] = React.useState(false);
  const [data, Setdata] = React.useState(null);
  const loadInformation = async () => {
    try {
      const req = await HttpRequest(`/nuc_answer/ssf_answers/${row?._id}`, {
        method: "GET",
      });
      Setdata(req);
      SetIsOpen(true);
    } catch ({ message }) {}
  };

  return (
    <>
      <ReactModal style={customStyles} isOpen={IsOpen} contentLabel="Example Modal">
        <div className="grid grid-cols-2 gap-4 gap-y-10 py-2">
          <h2 className="uppercase">{row?.programmeID.name} SELF STUDY FORM</h2>
          <button onClick={() => SetIsOpen(false)} className="text-right" style={{ color: "red" }}>
            <Close color="red" />
          </button>
        </div>
        <hr />
        <div style={{ overflowY: "auto", overflowX: "hidden", maxHeight: "80vh", maxWidth: "80vw" }}>
          {data?.map((r, i) => (
            <div
              key={`display_section_answer_${i}`}
              className="min-w-[100%] block max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:text-white dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700 mt-1 mb-2"
            >
              <h2>{r?.section?.title}</h2>
              <p>{r?.section?.description}</p>
              <hr />
              <div>
                {r?.question?.map((q, a) => {
                  const { question, answer } = q;
                  console.log(answer);
                  return (
                    <div key={`question_answer_${i}${a}`} className="border-solid border-2 rounded border-sky-200 m-3 br-10 text-wrap">
                      <div className="text-pretty p-3">
                        <b>Question: {question?.questionSerial}</b>
                        <br />
                        {question?.question}
                        <br />
                        <b>Self Study Form Answer:</b>
                        <br />
                        <span dangerouslySetInnerHTML={{ __html: answer?.Answer }}></span>
                        {answer?.isAttachement && (
                          <div>
                            <b>Is Attachment: </b>
                            <a href={answer?.isAttachement?.link} className="text-blue-600 cursor-pointer underline" target="_blank">
                              Open Attached Document
                            </a>
                          </div>
                        )}
                        {answer?.isGeoLocationRequired && (
                          <div>
                            <b>is GeoLocation Required:</b>
                          </div>
                        )}
                        {answer?.isPhotoRequired && (
                          <div>
                            <b>Is Photo Required:</b>
                          </div>
                        )}
                        {answer?.isQrScanRequired && (
                          <div>
                            <b>is Qr-Scan Required:</b>
                          </div>
                        )}
                        {answer?.isVideoRequired && (
                          <div>
                            <b>is Video Required:</b>
                          </div>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          ))}
        </div>
      </ReactModal>
      <IconButton onClick={loadInformation} aria-label="approve" className="cursor-pointer" color="success" style={{ cursor: "pointer" }}>
        <ViewArray color="red" />
      </IconButton>
    </>
  );
};

export default ViewSSFAnswersBtn;
