import React from "react";
import { MaterialReactTable } from "material-react-table";
import ClipLoader from "react-spinners/ClipLoader";
import HttpRequest from "../../functions/HttpRequest";
import SwalNotification from "../../functions/SwalNotification";
import ActivateItem from "../../components/ActivateItem";

const ViewSemester = () => {
  const [loading, setloading] = React.useState(true);
  const [college, setcollege] = React.useState(null);

  React.useEffect(() => {
    (async () => {
      try {
        setloading(true);
        const req = await HttpRequest(`/nuc_semester`, {
          method: "GET",
        });
        if (req.type) {
          SwalNotification(req.type, req.message, "VIEW COLLEGE");
          return;
        }
        setcollege(req);
        setloading(false);
      } catch ({ message }) {
        SwalNotification("error", message, "System Error");
        setloading(false);
      }
    })();
  }, []);
  const columns = [
    {
      accessorKey: "name",
      header: "Season Year",
    },
    {
      accessorKey: "description",
      header: "Season Description",
    },
    {
      accessorFn: (row) => <ActivateItem row={row} link="nuc_semester/active" />,
      header: "Status",
    },
    {
      accessorKey: "updatedAt",
      header: "Date & Time",
    },
  ];
  return (
    <div className="mx-auto max-w-7xl px-8 pt-4">
      <h2 className="text-4xl my-5 uppercase">View Accrediation Season</h2>
      <div className="grid grid-cols-1 gap-x-8 gap-y-10 py-2">
        {loading ? (
          <ClipLoader size={35} color={"#36D7B7"} />
        ) : (
          <div className="TheTable">
            <MaterialReactTable columns={columns} data={college} enableRowNumbers initialState={{ density: "compact" }} />
          </div>
        )}
      </div>
    </div>
  );
};

export default ViewSemester;
