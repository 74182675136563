import CreateSSF from "./CreateSSF";
import AssignedSSF from "./AssignedSSF";
import ChangeSSF from "./ChangeSSF";
import WidthdrawSSF from "./WidthdrawSSF";
import ViewSSFAnswers from "./ViewSSFAnswers";

const SelftStudyForm = {
  CreateSSF,
  AssignedSSF,
  ChangeSSF,
  WidthdrawSSF,
  ViewSSFAnswers,
};
export default SelftStudyForm;
