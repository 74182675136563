import React from "react";
import ClipLoader from "react-spinners/ClipLoader";
import SelectField from "../../components/SelectField";
import InputField from "../../components/InputField";
import SwalNotification from "../../functions/SwalNotification";
import HttpRequest from "../../functions/HttpRequest";
import is_null from "../../functions/is_null";
import useFetchInstitutionsActive from "../../hooks/useFetchInstitutionsActive";

const UpdateUniversity = () => {
  const [picked, setpicked] = React.useState(null);
  const [options, setoptions] = React.useState(null);
  const [loading, setloading] = React.useState(null);
  const { data, isError, isLoading } = useFetchInstitutionsActive();
  const _handleSubmit = async (e) => {
    try {
      e.preventDefault();
      setloading(true);
      const fd = new FormData(e.target);
      const university = fd.get("university");
      const name = fd.get("name");
      const vc = fd.get("vc");
      const address = fd.get("address");
      if (is_null(name) || is_null(vc) || is_null(address)) {
        throw new Error("Please fill the form properly");
      }

      const req = await HttpRequest(`/nuc_institutions/${university}`, {
        body: JSON.stringify({ name, vc, address }),
        method: "PUT",
      });
      if (req.type) {
        SwalNotification(req.type, req.message, "UPDATE UNIVERSITY");
      }
      setloading(false);
    } catch ({ message }) {
      SwalNotification("error", "Please fill the form prop");
      setloading(false);
    }
  };
  return (
    <div className="mx-auto max-w-7xl px-8 pt-4">
      <h2 className="text-4xl my-5 uppercase">Update University {picked && picked?.name}...</h2>
      <div className="grid grid-cols-1 gap-x-8 gap-y-10 py-2">
        <form onSubmit={_handleSubmit}>
          {isLoading ? (
            <span>Loading...</span>
          ) : (
            <SelectField
              name={"university"}
              label={"Pick a university"}
              options={data?.map((r) => ({ value: r?._id, label: r?.name }))}
              setValues={(r) => {
                const { value } = r;
                const filter = data?.filter((f) => f._id === value).shift();
                setpicked(filter);
              }}
            />
          )}
          {picked && (
            <>
              <InputField label="Enter University name" name="name" required value={picked ? picked?.name : ""} />
              <InputField label="Enter VC name" name="vc" required value={picked ? picked?.vc : ""} />
              <InputField label={"Enter University Address"} name="address" required min={10} value={picked ? picked?.address : ""} />

              <div className="flex items-center justify-center mb-4">{loading && <ClipLoader size={35} color={"#36D7B7"} />}</div>
              <button
                type="submit"
                disabled={loading}
                className="w-full bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded-lg focus:outline-none focus:bg-green-600"
              >
                {loading ? "Updating University..." : "Update University"}
              </button>
            </>
          )}
        </form>
      </div>
    </div>
  );
};

export default UpdateUniversity;
