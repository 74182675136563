import { toast } from "react-toastify";

const SwalNotification = (type, message, title) => {
  if (type === "success") {
    return toast.success(message, { title });
  }
  if (type === "error") {
    return toast.error(message, { title });
  }
  if (type === "warning") {
    return toast.warning(message, { title });
  }
  return toast.info(message, { title });
};

export default SwalNotification;
