import React from "react";
import { MaterialReactTable } from "material-react-table";
import ClipLoader from "react-spinners/ClipLoader";
import HttpRequest from "../../functions/HttpRequest";
import SwalNotification from "../../functions/SwalNotification";

const UniversityColleges = () => {
  const [loading, setloading] = React.useState(true);
  const [data, setdata] = React.useState(true);
  React.useEffect(() => {
    (async () => {
      try {
        setloading(true);
        const req = await HttpRequest(`/nuc_colleges`, {
          method: "GET",
        });
        if (req.type) {
          SwalNotification(req.type, req.message, "CREATE UNIVERSITY");
          return;
        }
        setdata(req);
        setloading(false);
      } catch ({ message }) {
        SwalNotification("error", message, "System Error");
        setloading(false);
      }
    })();
  }, []);
  const columns = [
    {
      accessorFn: (row) => `${row?.institutionID?.name}`,
      header: "University Name",
    },
    {
      accessorFn: (row) => `${row?.institutionID?.vc}`,
      header: "VC Name",
    },
    {
      accessorKey: "name",
      header: "Faculty/College",
    },
    {
      accessorKey: "updatedAt",
      header: "Date & Time",
    },
  ];

  return (
    <div className="mx-auto max-w-7xl px-8 pt-4" style={{ zIndex: -10 }}>
      <h2 className="text-4xl my-5 uppercase">View University's Colleges/Faculties</h2>
      <div className="grid grid-cols-1 gap-x-8 gap-y-10 py-2" style={{ zIndex: -10 }}>
        <>
          {loading ? (
            <ClipLoader size={35} color={"#36D7B7"} />
          ) : (
            <div className="TheTable">
              <MaterialReactTable columns={columns} data={data} enableRowNumbers initialState={{ density: "compact" }} />
            </div>
          )}
        </>
      </div>
    </div>
  );
};

export default UniversityColleges;
