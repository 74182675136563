import CreateProgramme from "./CreateProgramme";
import UpdateProgramme from "./UpdateProgramme";
import DeleteProgramme from "./DeleteProgramme";
import ViewProgramme from "./ViewProgramme";
import ProposeCreateProgramme from "./ProposeCreateProgramme";
import ProposeUpdateProgramme from "./ProposeUpdateProgramme";
import ProposeDeleteProgramme from "./ProposeDeleteProgramme";

export default {
  CreateProgramme,
  UpdateProgramme,
  DeleteProgramme,
  ViewProgramme,
  ProposeCreateProgramme,
  ProposeDeleteProgramme,
  ProposeUpdateProgramme,
};
