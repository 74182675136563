import React from "react";
import ClipLoader from "react-spinners/ClipLoader";
import InputField from "../../components/InputField";
import SelectField from "../../components/SelectField";
import HttpRequest from "../../functions/HttpRequest";
import SwalNotification from "../../functions/SwalNotification";
import is_null from "../../functions/is_null";
import TextAreaField from "../../components/TextAreaField";

const CreateCollege = () => {
	const [loading, setloading] = React.useState(false);
	const [loadingSave, setloadingSave] = React.useState(false);
	const [data, setdata] = React.useState(null);
	React.useEffect(() => {
		(async () => {
			try {
				setloading(true);
				const req = await HttpRequest(`/nuc_institutions/actives`, {
					method: "GET",
				});
				if (req.type) {
					SwalNotification(req.type, req.message, "CREATE UNIVERSITY");
					return;
				}
				const op = req?.map((r) => ({ value: r?._id, label: r?.name }));
				setdata(op);
				setloading(false);
			} catch ({ message }) {
				SwalNotification("error", message, "System Error");
				setloading(false);
			}
		})();
	}, []);
	const handleSubmit = async (values) => {
		try {
			setloadingSave(true);
			values.preventDefault();
			const fd = new FormData(values.target);
			const institutionID = fd.get("university");
			const name = fd.get("name");
			const description = fd.get("description");
			if (is_null(name) || is_null(institutionID) || is_null(description)) {
				throw new Error("Please fill the form properly");
			}

			const req = await HttpRequest(`/nuc_colleges`, {
				body: JSON.stringify({ institutionID, name, description }),
				method: "POST",
			});
			SwalNotification(req?.type, req?.message, "CREATE UNIVERSITY");
			setloadingSave(false);
		} catch ({ message }) {
			SwalNotification("error", message, "CREATE UNIVERSITY");
			setloadingSave(false);
		}
	};

	return (
		<div className="mx-auto max-w-7xl px-8 pt-4">
			<h2 className="text-4xl my-5 uppercase">
				Create College/Faculty/School...
			</h2>
			<div className="grid grid-cols-1 gap-x-8 gap-y-10 py-2">
				<form onSubmit={handleSubmit}>
					{loading ? (
						<div>Loading...</div>
					) : (
						<SelectField
							name={"university"}
							label={"Pick a university"}
							options={data}
						/>
					)}
					<InputField
						label={`Enter College/Faculty/School Name`}
						placeholder={`Enter College/Faculty/School Name`}
						required
						name={`name`}
					/>
					<TextAreaField
						label={`Enter College/Faculty/School Description`}
						placeholder={`Enter College/Faculty/School Description`}
						required
						name={`description`}
					/>
					<div className="flex items-center justify-center mb-4">
						{loadingSave && <ClipLoader size={35} color={"#36D7B7"} />}
					</div>
					<button
						type="submit"
						disabled={loadingSave}
						className="w-full bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded-lg focus:outline-none focus:bg-green-600"
					>
						{loadingSave
							? "Creating College/Faculty/School..."
							: "Create College/Faculty/School"}
					</button>
				</form>
			</div>
		</div>
	);
};

export default CreateCollege;
