import React from "react";

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <div className="container mx-auto flex justify-between items-center">
      <p className="text-center">&copy;{currentYear}</p>
      <p className="text-right">POWERED BY smartsourcing.ng</p>
    </div>
  );
};

export default Footer;
