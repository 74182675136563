import CreateExerciseType from "./CreateExerciseType";
import UpdateExerciseType from "./UpdateExerciseType";
import DeleteExerciseType from "./DeleteExerciseType";
import ViewExerciseType from "./ViewExerciseType";

export default {
  CreateExerciseType,
  UpdateExerciseType,
  DeleteExerciseType,
  ViewExerciseType,
};
