import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { routes, LoginPage, Error404 } from "./routes/routesConfig";
import AccountLayout from "./components/AccountLayout";
import ContextAPI from "./functions/ContextAPI";
import React from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import Modal from "react-modal";

function App() {
  const [values, setvalues] = React.useState({
    user: JSON.parse(localStorage.getItem("systemUser")),
  });
  Modal.setAppElement("#root");
  const queryClient = new QueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      <ContextAPI.Provider value={{ values, setvalues }}>
        <Router>
          <Routes>
            {routes.map((route, index) =>
              route.isOutlook ? (
                <Route key={index} element={<AccountLayout />}>
                  <Route path={route.path} element={route.element} />
                </Route>
              ) : (
                <Route key={index} path={route.path} element={route.element} />
              )
            )}
            <Route path="/" element={<LoginPage />} />
            <Route path="*" element={<Error404 />} />
          </Routes>
          <ToastContainer />
        </Router>
      </ContextAPI.Provider>
    </QueryClientProvider>
  );
}

export default App;
