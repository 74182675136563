import React from "react";
import Select from "react-select";

const SelectField = (props) => {
  return (
    <div className="mb-4">
      <label htmlFor={props.name} className="block font-bold text-lg text-gray-800 mb-2">
        {props.label}
      </label>
      <Select
        styles={{
          menu: (provided) => ({ ...provided, zIndex: 9999 }),
        }}
        name={props.name}
        id={props._id}
        options={props.options}
        onChange={props.setValues}
        {...props}
      />
    </div>
  );
};

export default SelectField;
