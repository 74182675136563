import React from "react";
import ClipLoader from "react-spinners/ClipLoader";
import InputField from "../../components/InputField";
import HttpRequest from "../../functions/HttpRequest";
import SwalNotification from "../../functions/SwalNotification";
import is_null from "../../functions/is_null";
import TextAreaField from "../../components/TextAreaField";
import SelectField from "../../components/SelectField";

const CreateSemester = () => {
  const [loadingSave, setloadingSave] = React.useState(false);

  const handleSubmit = async (values) => {
    try {
      setloadingSave(true);
      values.preventDefault();
      const fd = new FormData(values.target);
      const name = fd.get("name");
      const description = fd.get("description");
      if (is_null(name) || is_null(description)) {
        throw new Error("Please fill the form properly");
      }

      const req = await HttpRequest(`/nuc_semester`, {
        body: JSON.stringify({ name, description }),
        method: "POST",
      });
      SwalNotification(req?.type, req?.message, "CREATE UNIVERSITY");
      setloadingSave(false);
    } catch ({ message }) {
      SwalNotification("error", message, "CREATE UNIVERSITY");
      setloadingSave(false);
    }
  };

  return (
    <div className="mx-auto max-w-7xl px-8 pt-4">
      <h2 className="text-4xl my-5 uppercase">Create Accreditation Season...</h2>
      <div className="grid grid-cols-1 gap-x-8 gap-y-10 py-2">
        <form onSubmit={handleSubmit}>
          <InputField label={`Enter Season Yeas (2023)`} placeholder={`Enter Season Yeas (2023)`} required name={`name`} />

          <TextAreaField
            label={`Enter Season Description (2023 Season Accreditation)`}
            placeholder={`Enter Season Description (2023  Season Accreditation)`}
            required
            name={`description`}
          />
          <div className="flex items-center justify-center mb-4">{loadingSave && <ClipLoader size={35} color={"#36D7B7"} />}</div>
          <button
            type="submit"
            disabled={loadingSave}
            className="w-full bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded-lg focus:outline-none focus:bg-green-600"
          >
            {loadingSave ? "Submitting..." : "Submit"}
          </button>
        </form>
      </div>
    </div>
  );
};

export default CreateSemester;
