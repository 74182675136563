import React from "react";
import SwalNotification from "../functions/SwalNotification";
import HttpRequest from "../functions/HttpRequest";

const AutoAccept = ({ row, panel }) => {
  const [loading, setloading] = React.useState(false);

  const _notify = async () => {
    try {
      setloading(true);
      const req = await HttpRequest(`/lecturer/auto/${panel?.accessCode}`, {
        method: "GET",
        headers: { "Content-type": "application/json" },
      });
      SwalNotification(req?.type, req?.message, "System Error");
      setloading(false);
    } catch ({ message }) {
      SwalNotification("error", "Values are needed for start date and end date", "System Error");
      setloading(false);
    }
  };
  return (
    <button
      disabled={loading}
      onClick={_notify}
      className="uppercase bg-green-800 hover:bg-green-600 text-white font-bold py-2 px-4 rounded-lg focus:outline-none focus:bg-green-800"
    >
      {loading ? "Auto Accepting" : "Auto Accept"}
    </button>
  );
};

export default AutoAccept;
