import React from "react";
import { Link } from "react-router-dom";

const Error404 = () => {
  return (
    <div className="flex items-center justify-center h-screen bg-gray-100">
      <div className="w-full md:w-4/5 lg:w-3/5 xl:w-2/5 bg-white rounded-lg shadow-lg text-center p-8">
        <h2 className="text-9xl text-red-600 font-bold mb-8">404</h2>
        <p className="text-3xl text-gray-800 mb-6">OOP! Page Not Found</p>
        <p className="text-lg text-gray-700 mb-8">
          The page you are looking for does not exist.
        </p>
        <Link
          to="/login"
          className="block mx-auto max-w-xs px-6 py-3 bg-green-500 text-white rounded-lg text-center text-lg font-semibold shadow-md hover:bg-green-600 transition duration-300 ease-in-out"
        >
          Go to Login
        </Link>
      </div>
    </div>
  );
};

export default Error404;
