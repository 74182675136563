import React, { useState } from "react";
import { MaterialReactTable } from "material-react-table";
import ClipLoader from "react-spinners/ClipLoader";
import { IconButton } from "@mui/material";
import { DeleteForever, ThumbsUpDownOutlined } from "@mui/icons-material";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import HttpRequest from "../../functions/HttpRequest";
import Modal from "../../components/common/modal";

const ProposeCreateProgramme = () => {
	const [isModalOpen, setModalOpen] = useState(false);
	const [rejectReason, setRejectReason] = useState("");
	const [selectedId, setSelectedId] = useState(null);

	const queryClient = useQueryClient();

	const columns = [
		{
			accessorKey: "university",
			header: "University Name",
		},
		{
			accessorKey: "name",
			header: "New Department Name",
		},
		{
			accessorKey: "updatedAt",
			header: "Date & Time",
		},
		{
			accessorFn: (row) => (
				<IconButton
					key={`approve-${row._id}`}
					aria-label="approve"
					className="cursor-pointer"
					color="success"
					style={{ cursor: "pointer" }}
					onClick={() => handleApprove.mutate(row._id)}
				>
					<ThumbsUpDownOutlined />
				</IconButton>
			),
			header: "Approve",
		},
		{
			accessorFn: (row) => (
				<IconButton
					aria-label="delete"
					className="cursor-pointer"
					color="primary"
					style={{ cursor: "pointer" }}
				>
					<DeleteForever
						key={`reject-${row._id}`}
						aria-label="reject"
						className="cursor-pointer h-6 w-6 text-red-600"
						onClick={() => openModal(row._id)}
					/>
				</IconButton>
			),
			header: "Reject",
		},
	];

	const { data: programmes = [], isLoading } = useQuery({
		queryKey: ["departments"],
		queryFn: async () => {
			const res = await HttpRequest(`/ti_prop_department`, {
				method: "GET",
			});
			return res;
		},
	});

	const openModal = (id) => {
		setSelectedId(id);
		setModalOpen(true);
	};

	const closeModal = () => {
		setModalOpen(false);
		setRejectReason("");
	};

	const handleReject = useMutation({
		mutationFn: async ({ id, reason }) => {
			const res = await HttpRequest(`/ti_department/reason/reject`, {
				body: JSON.stringify({ id, reason }),
				method: "PUT",
				headers: { "Content-type": "application/json" },
			});
			return res;
		},
		onSuccess: () => {
			queryClient.invalidateQueries("departments");
			closeModal();
		},
	});

	const handleApprove = useMutation({
		mutationFn: async (id) => {
			const res = await HttpRequest(
				`/ti_prop_department/approve-programme/${id}/nuc`,
				{
					method: "PUT",
					headers: { "Content-type": "application/json" },
				}
			);
			return res;
		},
		onSuccess: () => {
			queryClient.invalidateQueries("departments");
		},
	});

	return (
		<>
			<div className="mx-auto max-w-7xl px-8 pt-4" style={{ zIndex: -10 }}>
				<h2 className="text-4xl my-5 uppercase">Proposed Create Department</h2>
				<div
					className="grid grid-cols-1 gap-x-8 gap-y-10 py-2"
					style={{ zIndex: -10 }}
				>
					<>
						{isLoading ? (
							<ClipLoader size={35} color={"#36D7B7"} />
						) : (
							<div className="TheTable">
								<MaterialReactTable
									columns={columns}
									data={programmes}
									enableRowNumbers
									initialState={{ density: "compact" }}
								/>
							</div>
						)}
					</>
				</div>
			</div>
			<Modal
				isOpen={isModalOpen}
				onClose={closeModal}
				title="Reject Proposed Programme"
			>
				<textarea
					value={rejectReason}
					onChange={(e) => setRejectReason(e.target.value)}
					className="w-full h-20 p-2 border border-gray-300 rounded"
					placeholder="Enter reason for rejection"
				/>
				<div className="flex gap-4 my-5">
					<button
						onClick={closeModal}
						className="px-4 py-2 bg-gray-500 text-white rounded-lg hover:bg-gray-600"
					>
						Cancel
					</button>
					<button
						onClick={() =>
							handleReject.mutate({
								id: selectedId,
								reason: rejectReason,
							})
						}
						className="px-4 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600"
					>
						Reject
					</button>
				</div>
			</Modal>
		</>
	);
};

export default ProposeCreateProgramme;
