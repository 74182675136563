import CreateAccreditation from "./CreateAccreditation";
// import UpdateAccreditation from "./UpdateAccreditation";
import StartAccreditation from "./StartAccreditation";
import AccredicationNotification from "./AccredicationNotification";
import ReAccredicationExercise from "./ReAccredicationExercise";

const index = {
  CreateAccreditation,
  AccredicationNotification,
  ReAccredicationExercise,
  StartAccreditation,
};
export default index;
