import React from "react";
import { useParams } from "react-router-dom";
import Logo from "../../assets/images/logo.png";
import { useQuery } from "@tanstack/react-query";
import HttpRequest from "../../functions/HttpRequest";
import is_null from "../../functions/is_null";

const Accept = () => {
  const { id } = useParams();
  const { data, isLoading } = useQuery({
    queryKey: ["AcceptanceSubmission"],
    queryFn: async () => {
      const req = await HttpRequest(`/lecturer/accept/${id}`, {
        method: "GET",
      });
      return req;
    },
  });
  return (
    <div className="h-screen flex flex-col items-center justify-center">
      <div>
        <img src={Logo} className="text-center w-40" alt="Logo" />
      </div>
      <div>{isLoading && "Please wait system is accepting your accreditation membership"}</div>
      <div>{!isLoading && <>{!is_null(data) && <div>{data?.message}</div>}</>}</div>
    </div>
  );
};

export default Accept;
