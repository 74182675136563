import React, { useEffect, useRef, useState } from "react";
import HttpRequest from "../functions/HttpRequest";
import SwalNotification from "../functions/SwalNotification";

const CheckIsMember = ({ info, ssf }) => {
  const [isChair, setIsChair] = useState(true);
  const [status, setStatus] = useState(false);
  const checkRef = useRef();

  useEffect(() => {
    setTimeout(() => {
      const lecturer = info?._id;
      const panel = ssf?.assignedPanel?.filter((r) => r?.id === lecturer && !r?.isChair);
      if (panel?.length > 0) {
        setStatus(true);
        setIsChair(false);
      } else {
        setIsChair(false);
      }
    }, 200);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const _checkedInput = async (e) => {
    e.preventDefault();
    try {
      const ssform = ssf.currentForm;
      const panel = info?._id;
      if (checkRef.current.checked) {
        const req = await HttpRequest(`/lecturer/is_chair/${ssform}/${panel}`, {
          method: "POST",
        });
        SwalNotification(req.type, req.message, "System Error");
        if (req.type === "success") {
          setStatus(true);
        }
      } else {
        //unchecked
        const req = await HttpRequest(`/lecturer/is_chair/${ssform}/${panel}`, {
          method: "DELETE",
        });
        SwalNotification(req.type, req.message, "System Error");
        if (req.type === "success") {
          setStatus(false);
        }
      }
    } catch ({ message }) {
      SwalNotification("error", message, "System Error");
    }
  };
  return (
    <div className="flex items-center">
      {isChair ? (
        "..."
      ) : (
        <div className="flex items-center mr-4">
          <input
            id={`member-checkbox-${info._id}`}
            type="checkbox"
            checked={status}
            ref={checkRef}
            onChange={_checkedInput}
            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
          />
          <label htmlFor={`member-checkbox-${info._id}`} className="ml-2 text-sm font-medium text-gray-900">
            Member
          </label>
        </div>
      )}
    </div>
  );
};

export default CheckIsMember;
