import React, { useState } from "react";
import { MaterialReactTable } from "material-react-table";
import ClipLoader from "react-spinners/ClipLoader";
import HttpRequest from "../../functions/HttpRequest";
import { EyeIcon } from "@heroicons/react/24/solid";
import Modal from "../../components/common/modal";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";

const ProposeUniversity = () => {
	const [isModalOpen, setModalOpen] = useState(false);
	const [selectedInstitutionId, setSelectedInstitutionId] = useState(null);
	const [rejectReason, setRejectReason] = useState("");
	const [showRejectTextArea, setShowRejectTextArea] = useState(false);

	const queryClient = useQueryClient();

	const openModal = (id) => {
		setSelectedInstitutionId(id);
		setModalOpen(true);
	};

	const closeModal = () => {
		setSelectedInstitutionId(null);
		setModalOpen(false);
	};

	const { data: institution, isLoading: institutionLoading } = useQuery({
		queryKey: ["institution", selectedInstitutionId],
		queryFn: async () => {
			if (!selectedInstitutionId) {
				return null;
			}
			const res = await HttpRequest(
				`/ti_institution/${selectedInstitutionId}/institution`,
				{
					method: "GET",
				}
			);
			return res;
		},
		enabled: !!selectedInstitutionId,
	});

	const { data: institutions, isLoading } = useQuery({
		queryKey: ["institutions"],
		queryFn: async () => {
			const res = await HttpRequest(`/ti_institution`, {
				method: "GET",
			});
			return res;
		},
	});

	const columns = [
		{
			accessorKey: "name",
			header: "Institution Name",
		},
		{
			accessorFn: (row) => (
				<a
					rel="noreferrer"
					href={
						row?.website?.includes("http")
							? row?.website
							: `http://${row?.website}`
					}
					target="_blank"
					className="text-blue-600"
				>
					{row?.website}
				</a>
			),
			header: "Website",
		},
		{
			accessorKey: "dapname",
			header: "DAP Name",
		},
		{
			accessorKey: "dapemail",
			header: "Dap Email",
		},
		{
			accessorKey: "updatedAt",
			header: "Date & Time",
		},
		{
			accessorFn: (row) => (
				<EyeIcon
					aria-label="approve"
					className="cursor-pointer h-6 w-6"
					onClick={() => openModal(row._id)}
				/>
			),
			header: "View",
		},
	];

	const handleApprove = useMutation({
		mutationFn: async (institutionId) => {
			const res = await HttpRequest(`/ti_institution/approve`, {
				body: JSON.stringify({ institutionId }),
				method: "POST",
				headers: { "Content-type": "application/json" },
			});
			return res;
		},
		onSuccess: () => {
			queryClient.invalidateQueries("institutions");
			closeModal();
		},
	});

	const handleReject = useMutation({
		mutationFn: async ({ institutionId, reason }) => {
			const res = await HttpRequest(`/ti_institution/reject`, {
				body: JSON.stringify({ institutionId, reason }),
				method: "PUT",
				headers: { "Content-type": "application/json" },
			});
			return res;
		},
		onSuccess: () => {
			queryClient.invalidateQueries("institutions");
			closeModal();
		},
	});

	return (
		<>
			<div className="mx-auto max-w-7xl px-8 pt-4" style={{ zIndex: -10 }}>
				<h2 className="text-4xl my-5 uppercase">Proposed university changes</h2>
				<div
					className="grid grid-cols-1 gap-x-8 gap-y-10 py-2"
					style={{ zIndex: -10 }}
				>
					<>
						{isLoading ? (
							<ClipLoader size={35} color={"#36D7B7"} />
						) : (
							<div className="TheTable">
								<MaterialReactTable
									columns={columns}
									data={institutions}
									enableRowNumbers
									initialState={{ density: "compact" }}
								/>
							</div>
						)}
					</>
				</div>
			</div>
			<Modal
				isOpen={isModalOpen}
				onClose={closeModal}
				title="Proposed change of institution"
			>
				{institutionLoading ? (
					<ClipLoader size={25} color={"#36D7B7"} />
				) : institution ? (
					<div>
						<p className="text-lg font-bold">New Institution Record</p>
						<p className="">Institution: {institution?.name}</p>
						<p>Website: {institution?.website}</p>
						<p>DAP Name: {institution?.dapname}</p>
						<p>DAP Email: {institution?.dapemail}</p>
						<hr className="h-px my-8 bg-gray-200 border-0"></hr>
						{/* Add more fields as necessary */}

						<p className="text-lg font-bold">Old Institution Record</p>

						<p className="">Institution: {institution?.old_record?.name}</p>
						<p>Website: {institution?.old_record?.website}</p>
						<p>DAP Name: {institution?.old_record?.dapname}</p>
						<p>DAP Email: {institution?.old_record?.dapemail}</p>
					</div>
				) : (
					<p>No institution data available</p>
				)}
				<div className="flex gap-4 my-5">
					<button
						onClick={() => {
							setShowRejectTextArea(true);
							setSelectedInstitutionId(selectedInstitutionId);
						}}
						className="px-4 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600"
					>
						Reject
					</button>
					<button
						onClick={() => handleApprove.mutate(selectedInstitutionId)}
						className="px-4 py-2 bg-green-500 text-white rounded-lg hover:bg-green-600"
					>
						Approve
					</button>
				</div>
			</Modal>

			{showRejectTextArea && (
				<Modal
					isOpen={showRejectTextArea}
					onClose={() => setShowRejectTextArea(false)}
					title="Reject Institution"
				>
					<textarea
						value={rejectReason}
						onChange={(e) => setRejectReason(e.target.value)}
						className="w-full h-20 p-2 border border-gray-300 rounded"
						placeholder="Enter reason for rejection"
					/>
					<div className="flex gap-4 my-5">
						<button
							onClick={() => setShowRejectTextArea(false)}
							className="px-4 py-2 bg-gray-500 text-white rounded-lg hover:bg-gray-600"
						>
							Cancel
						</button>
						<button
							onClick={() =>
								handleReject.mutate({
									institutionId: selectedInstitutionId,
									reason: rejectReason,
								})
							}
							className="px-4 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600"
						>
							Reject
						</button>
					</div>
				</Modal>
			)}
		</>
	);
};

export default ProposeUniversity;
