import { Formik, Form } from "formik";
import React from "react";
import * as Yup from "yup";
import { MaterialReactTable } from "material-react-table";
import ClipLoader from "react-spinners/ClipLoader";
import SelectField from "../../components/SelectField";
import { toast, ToastContainer } from "react-toastify";

const validationSchema = Yup.object().shape({
  university: Yup.string().required("You must pick a university"),
  college: Yup.string().required("You must pick a college"),
  department: Yup.string().min(10, "Department name must be at least 24 characters").required("Enter the department name"),
});
const ViewCollegeDepartments = () => {
  const [loading, setloading] = React.useState(true);
  React.useEffect(() => {
    setTimeout(() => {
      setloading(false);
    }, 5000);
  }, []);
  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      setSubmitting(true);
      setTimeout(() => {
        setSubmitting(false);
      }, 200);
    } catch (error) {
      toast.error("An error occurred. Please try again.");
    } finally {
      setSubmitting(false);
    }
  };
  const columns = [
    {
      accessorKey: "university",
      header: "University Name",
    },
    {
      accessorKey: "vc",
      header: "VC Name",
    },
    {
      accessorKey: "college",
      header: "Faculty/College",
    },
    {
      accessorKey: "updatedAt",
      header: "Date & Time",
    },
  ];
  const data = [
    {
      university: "Cosmopolitan University Abuja",
      vc: "Prof. Carl Adams",
      college: "Faculty of Management & Social Science",
      updatedAt: new Date().toISOString(),
    },
    {
      university: "Cosmopolitan University Abuja",
      vc: "Prof. Carl Adams",
      college: "Faculty of Natural & Applied Sciences",
      updatedAt: new Date().toISOString(),
    },
    {
      university: "Baze University Abuja",
      vc: "Prof. Tahir Mamman",
      college: "Faculty of Law",
      updatedAt: new Date().toISOString(),
    },
    {
      university: "Baze University Abuja",
      vc: "Prof. Tahir Mamman",
      college: "Faculty of Engineering",
      updatedAt: new Date().toISOString(),
    },
    {
      university: "University of Abuja",
      vc: "Prof. New Comings",
      college: "Faculty of Engineering",
      updatedAt: new Date().toISOString(),
    },
    {
      university: "University of Maiduguri",
      vc: "Prof. Graduated Student",
      college: "College of Infromation & Communication Technology",
      updatedAt: new Date().toISOString(),
    },
  ];
  return (
    <div className="mx-auto max-w-7xl px-8 pt-4">
      <h2 className="text-4xl my-5 uppercase">View Colleges/Faculties</h2>
      <div className="grid grid-cols-1 gap-x-8 gap-y-10 py-2">
        <Formik initialValues={{ name: "", address: "", vc: "", selected: "" }} validationSchema={validationSchema} onSubmit={handleSubmit}>
          {({ isSubmitting }) => (
            <Form>
              <div className="grid grid-cols-2 gap-x-8 gap-y-10 py-2">
                <SelectField
                  name={"university"}
                  label={"Pick a university"}
                  options={[
                    { value: "US", label: "Baze University Abuja" },
                    { value: "CA", label: "Cosmopolitan University Abuja" },
                    { value: "FR", label: "University of Maiduguri" },
                    { value: "DE", label: "University of Abuja" },
                  ]}
                />
                <SelectField
                  name={"college"}
                  label={"Pick a college"}
                  options={[
                    {
                      value: "US",
                      label: "College Natural & Applied Sciences",
                    },
                    { value: "CA", label: "College of Engineering" },
                    { value: "FR", label: "College of Agricultural Science" },
                    { value: "DE", label: "College of Health" },
                    { value: "DE", label: "College of Humanities" },
                  ]}
                />
              </div>
            </Form>
          )}
        </Formik>
      </div>
      <>
        {loading ? (
          <ClipLoader size={35} color={"#36D7B7"} />
        ) : (
          <div className="TheTable">
            <MaterialReactTable columns={columns} data={data} enableRowNumbers initialState={{ density: "compact" }} />
          </div>
        )}
      </>
    </div>
  );
};

export default ViewCollegeDepartments;
