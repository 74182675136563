import React from "react";
import { MaterialReactTable } from "material-react-table";
import SelectField from "../../components/SelectField";
import InputField from "../../components/InputField";
import is_null from "../../functions/is_null";
import SwalNotification from "../../functions/SwalNotification";
import HttpRequest from "../../functions/HttpRequest";
import PanelMembers from "../../components/PanelMembers";

const AccredicationNotification = () => {
  const [loading, setloading] = React.useState(false);
  const [selected, setselected] = React.useState(null);
  const [start, setstart] = React.useState(null);
  const [end, setend] = React.useState(null);
  const [data, setdata] = React.useState(null);

  const columns = [
    {
      accessorFn: (row) => `${row?.departmentID?.collegeID?.institutionID?.name}`,
      header: "University",
    },
    {
      accessorFn: (row) => `${row?.departmentID?.collegeID?.name}`,
      header: "College/Faculty",
    },
    {
      accessorFn: (row) => `${row?.departmentID?.name}`,
      header: "Department",
    },
    {
      accessorFn: (row) => `${row?.name}`,
      header: "Programme",
    },
    {
      accessorFn: (row) => <PanelMembers row={row?.panel} univ={row?.departmentID?.collegeID?.name} prog={row?.name} />,
      header: "Panel Members",
    },
  ];

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      setloading(true);
      if (!is_null(start) && !is_null(end)) {
        if (new Date(start) >= new Date(end)) {
          SwalNotification("error", "End date cannot be less than or equal to start date", "System Error");
          return;
        }
      }
      const req = await HttpRequest(`/nuc_programmes/ssf/report`, {
        body: JSON.stringify({ selected, start, end }),
        method: "POST",
      });
      if (!is_null(req?.type)) {
        SwalNotification(req?.type, req?.message, "CREATE UNIVERSITY");
        setloading(false);
        return;
      }
      setdata(req);
      setloading(false);
    } catch ({ message }) {
      SwalNotification("error", message, "ERROR ENCOUNTERED");
      setloading(false);
    }
  };
  return (
    <>
      <div className="mx-auto max-w-7xl px-8 pt-4">
        <h2 className="text-4xl my-5 uppercase">Accredication Notification</h2>
        <div className="grid grid-cols-1 gap-x-8 gap-y-10 py-2">
          <form onSubmit={handleSubmit} className="grid grid-cols-4 gap-x-8 gap-y-10 py-2">
            <SelectField
              name={"university"}
              label={"Pick report type for the year"}
              options={[
                { value: 0, label: "Pending Accreditation" },
                { value: 1, label: "Active Accreditation" },
                { value: 2, label: "Completed Accreditation" },
              ]}
              setValues={setselected}
            />
            <InputField label="Start Date" onChange={(e) => setstart(e.target.value)} type="date" placeholder="Start Date" />
            <InputField label="End Date" onChange={(e) => setend(e.target.value)} type="date" placeholder="End Date" />
            <div className="pt-6">
              <button
                type="submit"
                disabled={loading}
                className="w-full bg-green-500 hover:bg-green-600 text-white font-bold py-4 px-4  rounded-lg focus:outline-none focus:bg-green-600"
              >
                {loading ? "Generating Report..." : "Generate Report"}
              </button>
            </div>
          </form>
        </div>
      </div>
      {data && (
        <div className="TheTable m-5">
          <MaterialReactTable columns={columns} data={data} enableRowNumbers initialState={{ density: "compact" }} />
        </div>
      )}
    </>
  );
};

export default AccredicationNotification;
