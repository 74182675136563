import React from "react";

const CheckBox = React.forwardRef((props, ref) => {
  return (
    <div className="flex items-center p-4 ps-2 max- border border-gray-200 rounded dark:border-gray-700">
      <input {...props} ref={ref} id={props?.name} className="mr-2 focus:outline-none focus:border-green-500" />

      <label htmlFor={props.name} className="block font-bold text-gray-800">
        {props.label}
      </label>
    </div>
  );
});

export default CheckBox;
