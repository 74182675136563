import CreateSection from "./CreateSection";
import UpdateSection from "./UpdateSection";
import DeleteSection from "./DeleteSection";
import ViewSection from "./ViewSection";
import ExerciseSection from "./ExerciseSection";

export default {
  CreateSection,
  UpdateSection,
  DeleteSection,
  ViewSection,
  ExerciseSection,
};
