import React from "react";
import ClipLoader from "react-spinners/ClipLoader";
import InputField from "../../components/InputField";
import HttpRequest from "../../functions/HttpRequest";
import SwalNotification from "../../functions/SwalNotification";
import is_null from "../../functions/is_null";

const CreateUniversity = () => {
  const [loading, setloading] = React.useState(false);
  const handleSubmit = async (values) => {
    try {
      setloading(true);
      values.preventDefault();
      const fd = new FormData(values.target);
      const name = fd.get("name");
      const vc = fd.get("vc");
      const address = fd.get("address");
      if (is_null(name) || is_null(vc) || is_null(address)) {
        throw new Error("Please fill the form properly");
      }

      const req = await HttpRequest(`/nuc_institutions`, {
        body: JSON.stringify({ name, vc, address }),
        method: "POST",
      });
      SwalNotification(req?.type, req?.message, "CREATE UNIVERSITY");
      setloading(false);
    } catch ({ message }) {
      SwalNotification("error", message, "CREATE UNIVERSITY");
      setloading(false);
    }
  };
  return (
    <div className="mx-auto max-w-7xl px-8 pt-4">
      <h2 className="text-4xl my-5 uppercase">Create University...</h2>
      <div className="grid grid-cols-1 gap-x-8 gap-y-10 py-2">
        <form onSubmit={handleSubmit}>
          <InputField name={`name`} required label={`Enter University name`} />
          <InputField name={`vc`} required label={`Enter VC Name`} />
          <InputField name={`address`} required label={`Enter University Address`} />

          <div className="flex items-center justify-center mb-4">{loading && <ClipLoader size={35} color={"#36D7B7"} />}</div>
          <button
            type="submit"
            disabled={loading}
            className="w-full bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded-lg focus:outline-none focus:bg-green-600"
          >
            {loading ? "Creating University..." : "Create University"}
          </button>
        </form>
      </div>
    </div>
  );
};

export default CreateUniversity;
