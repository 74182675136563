import React from "react";
import { MaterialReactTable } from "material-react-table";
import ClipLoader from "react-spinners/ClipLoader";
import SelectField from "../../components/SelectField";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { toast, ToastContainer } from "react-toastify";

const validationSchema = Yup.object().shape({
  university: Yup.string().required("You must pick a university"),
  college: Yup.string().required("You must pick a college"),
});

const ViewDepartmentProgram = () => {
  const [loading, setloading] = React.useState(false);

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      console.log(values);
      setloading(true);
      setSubmitting(true);
      setTimeout(() => {
        setSubmitting(false);
        setloading(false);
      }, 200);
    } catch (error) {
      toast.error("An error occurred. Please try again.");
    } finally {
      setSubmitting(false);
    }
  };

  const columns = [
    {
      accessorKey: "university",
      header: "University Name",
    },

    {
      accessorKey: "college",
      header: "Faculty/College",
    },
    {
      accessorKey: "department",
      header: "Department",
    },
    {
      accessorKey: "programme",
      header: "Programme",
    },
    {
      accessorKey: "updatedAt",
      header: "Date & Time",
    },
  ];
  const data = [
    {
      university: "Cosmopolitan University Abuja",
      department: "Department of Sociology",
      college: "Faculty of Management & Social Science",
      programme: "Bs.c. Sociology",
      updatedAt: new Date().toISOString(),
    },
    {
      university: "Cosmopolitan University Abuja",
      department: "Department of Sociology",
      college: "Faculty of Management & Social Science",
      programme: "Bs.c. Psycology",
      updatedAt: new Date().toISOString(),
    },
    {
      university: "Cosmopolitan University Abuja",
      department: "Department of Sociology",
      college: "Faculty of Management & Social Science",
      programme: "Bs.c. Human Sociology",
      updatedAt: new Date().toISOString(),
    },
    {
      university: "Cosmopolitan University Abuja",
      department: "Department of Sociology",
      college: "Faculty of Management & Social Science",
      programme: "Bs.c. Anger Management",
      updatedAt: new Date().toISOString(),
    },
    {
      university: "Cosmopolitan University Abuja",
      department: "Department of Sociology",
      college: "Faculty of Management & Social Science",
      programme: "Bs.c. Educational Sociology",
      updatedAt: new Date().toISOString(),
    },
    {
      university: "Cosmopolitan University Abuja",
      department: "Department of Sociology",
      college: "Faculty of Management & Social Science",
      programme: "Bs.c. Physical Sociology",
      updatedAt: new Date().toISOString(),
    },
  ];
  return (
    <div className="mx-auto max-w-7xl px-8 pt-4">
      <h2 className="text-4xl my-5 uppercase">View Department Programmes</h2>
      <div className="grid grid-cols-1 gap-x-8 gap-y-10 py-2">
        <Formik
          initialValues={{ university: "", college: "" }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting }) => (
            <Form>
              <div className="grid grid-cols-4 gap-x-8 gap-y-10 py-2">
                <SelectField
                  name={"university"}
                  label={"Pick university"}
                  options={[
                    { value: "US", label: "Baze University Abuja" },
                    { value: "CA", label: "Cosmopolitan University Abuja" },
                    { value: "FR", label: "University of Maiduguri" },
                    { value: "DE", label: "University of Abuja" },
                  ]}
                />
                <SelectField
                  name={"college"}
                  label={"Pick a college"}
                  options={[
                    {
                      value: "US",
                      label: "College Natural & Applied Sciences",
                    },
                    { value: "CA", label: "College of Engineering" },
                    { value: "FR", label: "College of Agricultural Science" },
                    { value: "DE", label: "College of Health" },
                    { value: "DE", label: "College of Humanities" },
                  ]}
                />
                <SelectField
                  name={"department"}
                  label={"Pick a Department"}
                  options={[
                    {
                      value: "US",
                      label: "College Natural & Applied Sciences",
                    },
                    { value: "CA", label: "College of Engineering" },
                    { value: "FR", label: "College of Agricultural Science" },
                    { value: "DE", label: "College of Health" },
                    { value: "DE", label: "College of Humanities" },
                  ]}
                />
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="w-full bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded-lg focus:outline-none focus:bg-green-600"
                >
                  {isSubmitting ? "Fetching Department..." : "Fetch Department"}
                </button>
              </div>
            </Form>
          )}
        </Formik>
        {loading ? (
          <ClipLoader size={35} color={"#36D7B7"} />
        ) : (
          <div className="TheTable">
            <MaterialReactTable
              columns={columns}
              data={data}
              enableRowNumbers
              initialState={{ density: "compact" }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default ViewDepartmentProgram;
