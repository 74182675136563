import { UserGroupIcon } from "@heroicons/react/24/outline";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import ClipLoader from "react-spinners/ClipLoader";
import { toast } from "react-toastify";
import { MaterialReactTable } from "material-react-table";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import HttpRequest from "../functions/HttpRequest";
// import Modal from "../components/common/modal";
import ReactModal from "react-modal";
import { Close } from "@mui/icons-material";
import ShowWorkedAt from "./ShowWorkedAt";
import CheckIsChair from "./CheckIsChair";
import CheckIsMember from "./CheckIsMember";

const customStyles = {
  overlay: {
    zIndex: 1000,
  },
  content: {
    top: "50%",
    left: "50%",
    minWidth: "70%",
    right: "auto",
    zIndex: 1001,
    textTransform: "uppercase",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

// Define Zod schema
const searchSchema = z.object({
  searchQuery: z.string().min(3, "Search query must be at least 3 characters"),
});

const AccreditationPanel = ({ course }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedRoles, setSelectedRoles] = useState({});
  const queryClient = useQueryClient();
  const handleCheckboxChange = (role, lecturerId) => {
    setSelectedRoles((prev) => {
      return {
        ...prev,
        [lecturerId]: role,
      };
    });
    updateRole({ lecturerId, role });
  };

  const {
    handleSubmit,
    register,
    formState: { isSubmitting, errors },
  } = useForm({
    resolver: zodResolver(searchSchema),
    defaultValues: {
      searchQuery: "",
    },
  });
  const { data, isLoading, error } = useQuery({
    queryKey: ["accreditationSearch", searchTerm],
    queryFn: async () => {
      const res = await HttpRequest(`/lecturer/search/${searchTerm}/${course?._id}`, {
        method: "GET",
      });
      return res;
    },
    enabled: !!searchTerm,
  });

  const handleSearch = (values) => {
    setSearchTerm(values.searchQuery);
  };

  const columns = [
    {
      accessorFn: (row) => `${row.firstname}  ${row?.middlename}  ${row.lastname}  `,
      header: "Lecturer Name",
    },
    {
      accessorKey: "designation",
      header: "Designation",
    },
    {
      accessorKey: "panelNow",
      header: "Current Year Accr.",
    },
    {
      accessorKey: "panelAll",
      header: "Total Accr.",
    },
    {
      accessorFn: (row) => <ShowWorkedAt worked={row?.employee} programme={course?.departmentID?.collegeID?.institutionID?._id} />,
      header: "Worked At",
    },
    {
      accessorFn: (row) => <CheckIsChair info={row} ssf={course} />,
      header: "Is Chair",
    },
    {
      accessorFn: (row) => <CheckIsMember info={row} ssf={course} />,
      header: "Member",
    },
  ];

  const { mutate: updateRole } = useMutation({
    mutationFn: async (roleData) => {
      const res = await HttpRequest(`/lecturer/update-role`, {
        method: "POST",
        body: JSON.stringify(roleData),
        headers: {
          "Content-Type": "application/json",
        },
      });
      return res;
    },

    onSuccess: () => {
      toast.success("Role updated successfully!");
      queryClient.invalidateQueries(["accreditationSearch", searchTerm]);
    },
    onError: (r) => {
      toast.error("Failed to update role.");
    },
  });

  return (
    <div className="z-50">
      <div className="flex flex-col gap-2">
        <form onSubmit={handleSubmit(handleSearch)} className="flex w-[100%] gap-2">
          <div className="w-[100%]">
            <input
              type="search"
              id="search"
              placeholder="Search for panel member (search by name of member, course, or program related information)"
              className="block w-full p-3 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 text-base"
              {...register("searchQuery")}
            />
            {errors.searchQuery && <p className="text-red-500 text-sm">{errors.searchQuery.message}</p>}
          </div>
        </form>
        <div className="flex max-w-[100%] ">
          {isLoading && <ClipLoader size={35} color={"#36D7B7"} />}
          {error && <p className="text-red-500 text-sm">An error occurred.</p>}
          {data && (
            <div className="w-[100%]">
              <MaterialReactTable columns={columns} data={data || []} enableRowNumbers initialState={{ density: "compact" }} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AccreditationPanel;
