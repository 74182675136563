import React from "react";
import ClipLoader from "react-spinners/ClipLoader";
import { MaterialReactTable } from "material-react-table";
import HttpRequest from "../../functions/HttpRequest";
import SwalNotification from "../../functions/SwalNotification";
import SelectField from "../../components/SelectField";
import is_null from "../../functions/is_null";

const ViewExerciseSection = () => {
  const [loading, setloading] = React.useState(false);
  const [tableLoading, setTableLoading] = React.useState(false);
  const [isOptions, setOptions] = React.useState(null);
  const [isExercise, setExercise] = React.useState(null);
  const [isPickedExercise, setPickedExercise] = React.useState(null);
  const [isData, setData] = React.useState(null);
  React.useEffect(() => {
    (async () => {
      try {
        setloading(true);
        const req = await HttpRequest(`/nuc_exercise_type`, {
          method: "GET",
        });
        if (req.type) {
          SwalNotification(req.type, req.message, "CREATE UNIVERSITY");
          return;
        }
        const op = req?.map((r) => ({ value: r?._id, label: r?.title }));
        setOptions(op);
        setExercise(req);
        setloading(false);
      } catch ({ message }) {
        SwalNotification("error", message, "System Error");
        setloading(false);
      }
    })();
  }, []);
  React.useEffect(() => {
    if (!is_null(isPickedExercise)) {
      (async () => {
        try {
          setTableLoading(true);
          const req = await HttpRequest(`/nuc_sections/execise/${isPickedExercise?._id}`, {
            method: "GET",
          });
          if (req.type) {
            SwalNotification(req.type, req.message, "CREATE UNIVERSITY");
            return;
          }
          setData(req);
          setTableLoading(false);
        } catch ({ message }) {
          SwalNotification("error", message, "System Error");
          setTableLoading(false);
        }
      })();
    }
  }, [isPickedExercise]);
  const columns = [
    {
      accessorKey: "title",
      header: "Exercise Title",
    },
    {
      accessorKey: "description",
      header: "Exercise Description",
    },
    {
      accessorFn: (row) => `${row.status ? "Active" : "Not Active"}`,
      header: "Status",
    },
    {
      accessorKey: "updatedAt",
      header: "Date & Time",
    },
  ];

  return (
    <div className="mx-auto max-w-7xl px-8 pt-4">
      <h2 className="text-4xl my-5 uppercase">View Programme</h2>
      <div className="grid grid-cols-1 gap-x-8 gap-y-10 py-2">
        {loading ? (
          <div>Loading...</div>
        ) : (
          <div className="grid grid-cols-1 gap-x-8 gap-y-10 py-2">
            <SelectField
              name={"exerciseType"}
              label={"Pick a exercise type"}
              options={isOptions}
              setValues={(r) => {
                const { value } = r;
                const filter = isExercise?.filter((f) => f._id === value).shift();
                setPickedExercise(filter);
              }}
            />
          </div>
        )}
      </div>
      <div className="grid grid-cols-1 gap-x-8 gap-y-10 py-2">
        {tableLoading ? (
          <ClipLoader size={35} color={"#36D7B7"} />
        ) : (
          <div className="TheTable">
            {isData && <MaterialReactTable columns={columns} data={isData} enableRowNumbers initialState={{ density: "compact" }} />}
          </div>
        )}
      </div>
    </div>
  );
};

export default ViewExerciseSection;
