import PublicRoute from "./PublicRoute";
import ProtectedRoute from "./ProtectedRoute";
import LoginPage from "../pages/Login";
import RegistrationPage from "../pages/Registration";
import DashboardPage from "../pages/Dashboard";
import Error404 from "../pages/NotFound";
import University from "../pages/University";
import College from "../pages/College";
import Department from "../pages/Department";
import Programme from "../pages/Programme";
import ExerciseType from "../pages/ExerciseType";
import Section from "../pages/Section";
import Session from "../pages/Session";
import Question from "../pages/Question";
import Accreditation from "../pages/Accreditation";
import Signout from "../pages/Signout";
import SelftStudyForm from "../pages/SelfStudyForm";
import Accept from "../pages/Login/Accept";

const routes = [
  {
    path: "/login",
    element: (
      <PublicRoute restricted={true}>
        <LoginPage />
      </PublicRoute>
    ),
  },
  {
    path: "/accept/:id",
    element: (
      <PublicRoute restricted={true}>
        <Accept />
      </PublicRoute>
    ),
  },
  {
    path: "/register",
    element: (
      <PublicRoute restricted={true}>
        <RegistrationPage />
      </PublicRoute>
    ),
  },
  {
    path: "/dashboard",
    element: (
      <ProtectedRoute>
        <DashboardPage />
      </ProtectedRoute>
    ),
    isOutlook: true,
  },
  {
    path: "/create-university",
    element: (
      <ProtectedRoute>
        <University.CreateUniversity />
      </ProtectedRoute>
    ),
    isOutlook: true,
  },
  {
    path: "/update-university",
    element: (
      <ProtectedRoute>
        <University.UpdateUniversity />
      </ProtectedRoute>
    ),
    isOutlook: true,
  },
  {
    path: "/delete-university",
    element: (
      <ProtectedRoute>
        <University.DeleteUniversity />
      </ProtectedRoute>
    ),
    isOutlook: true,
  },

  {
    path: "/view-universities",
    element: (
      <ProtectedRoute>
        <University.ViewUniversities />
      </ProtectedRoute>
    ),
    isOutlook: true,
  },
  {
    path: "/propose-create-university",
    element: (
      <ProtectedRoute>
        <University.ProposeCreateUniversity />
      </ProtectedRoute>
    ),
    isOutlook: true,
  },
  {
    path: "/propose-university",
    element: (
      <ProtectedRoute>
        <University.ProposeUniversity />
      </ProtectedRoute>
    ),
    isOutlook: true,
  },
  {
    path: "/university-colleges",
    element: (
      <ProtectedRoute>
        <University.UniversityColleges />
      </ProtectedRoute>
    ),
    isOutlook: true,
  },

  {
    path: "/create-college",
    element: (
      <ProtectedRoute>
        <College.CreateCollege />
      </ProtectedRoute>
    ),
    isOutlook: true,
  },
  {
    path: "/update-college",
    element: (
      <ProtectedRoute>
        <College.UpdateCollege />
      </ProtectedRoute>
    ),
    isOutlook: true,
  },
  {
    path: "/delete-college",
    element: (
      <ProtectedRoute>
        <College.DeleteCollege />
      </ProtectedRoute>
    ),
    isOutlook: true,
  },
  {
    path: "/view-college",
    element: (
      <ProtectedRoute>
        <College.ViewColleges />
      </ProtectedRoute>
    ),
    isOutlook: true,
  },
  {
    path: "/view-college-department",
    element: (
      <ProtectedRoute>
        <College.ViewCollegeDepartments />
      </ProtectedRoute>
    ),
    isOutlook: true,
  },
  {
    path: "/propose-create-college",
    element: (
      <ProtectedRoute>
        <College.ProposeCreateCollege />
      </ProtectedRoute>
    ),
    isOutlook: true,
  },
  {
    path: "/propose-update-college",
    element: (
      <ProtectedRoute>
        <College.ProposeUpdateCollege />
      </ProtectedRoute>
    ),
    isOutlook: true,
  },
  {
    path: "/propose-delete-college",
    element: (
      <ProtectedRoute>
        <College.ProposeDeleteCollege />
      </ProtectedRoute>
    ),
    isOutlook: true,
  },
  {
    path: "/create-department",
    element: (
      <ProtectedRoute>
        <Department.CreateDepartment />
      </ProtectedRoute>
    ),
    isOutlook: true,
  },
  {
    path: "/update-department",
    element: (
      <ProtectedRoute>
        <Department.UpdateDepartment />
      </ProtectedRoute>
    ),
    isOutlook: true,
  },
  {
    path: "/delete-department",
    element: (
      <ProtectedRoute>
        <Department.DeleteDepartment />
      </ProtectedRoute>
    ),
    isOutlook: true,
  },
  {
    path: "/view-department",
    element: (
      <ProtectedRoute>
        <Department.ViewDepartment />
      </ProtectedRoute>
    ),
    isOutlook: true,
  },
  {
    path: "/department-programme",
    element: (
      <ProtectedRoute>
        <Department.ViewDepartmentProgram />
      </ProtectedRoute>
    ),
    isOutlook: true,
  },
  {
    path: "/propose-create-department",
    element: (
      <ProtectedRoute>
        <Department.ProposeCreateDepartment />
      </ProtectedRoute>
    ),
    isOutlook: true,
  },
  {
    path: "/propose-update-department",
    element: (
      <ProtectedRoute>
        <Department.ProposeUpdateDepartment />
      </ProtectedRoute>
    ),
    isOutlook: true,
  },
  {
    path: "/propose-delete-department",
    element: (
      <ProtectedRoute>
        <Department.ProposeDeleteDepartment />
      </ProtectedRoute>
    ),
    isOutlook: true,
  },
  {
    path: "/create-programme",
    element: (
      <ProtectedRoute>
        <Programme.CreateProgramme />
      </ProtectedRoute>
    ),
    isOutlook: true,
  },
  {
    path: "/update-programme",
    element: (
      <ProtectedRoute>
        <Programme.UpdateProgramme />
      </ProtectedRoute>
    ),
    isOutlook: true,
  },
  {
    path: "/delete-programme",
    element: (
      <ProtectedRoute>
        <Programme.DeleteProgramme />
      </ProtectedRoute>
    ),
    isOutlook: true,
  },
  {
    path: "/view-programme",
    element: (
      <ProtectedRoute>
        <Programme.ViewProgramme />
      </ProtectedRoute>
    ),
    isOutlook: true,
  },
  {
    path: "/propose-create-programme",
    element: (
      <ProtectedRoute>
        <Programme.ProposeCreateProgramme />
      </ProtectedRoute>
    ),
    isOutlook: true,
  },
  {
    path: "/propose-update-programme",
    element: (
      <ProtectedRoute>
        <Programme.ProposeUpdateProgramme />
      </ProtectedRoute>
    ),
    isOutlook: true,
  },
  {
    path: "/propose-delete-programme",
    element: (
      <ProtectedRoute>
        <Programme.ProposeDeleteProgramme />
      </ProtectedRoute>
    ),
    isOutlook: true,
  },
  {
    path: "/propose-delete-programme",
    element: (
      <ProtectedRoute>
        <Programme.ProposeDeleteProgramme />
      </ProtectedRoute>
    ),
    isOutlook: true,
  },
  {
    path: "/create-exercise-type",
    element: (
      <ProtectedRoute>
        <ExerciseType.CreateExerciseType />
      </ProtectedRoute>
    ),
    isOutlook: true,
  },
  {
    path: "/update-exercise-type",
    element: (
      <ProtectedRoute>
        <ExerciseType.UpdateExerciseType />
      </ProtectedRoute>
    ),
    isOutlook: true,
  },
  {
    path: "/delete-exercise-type",
    element: (
      <ProtectedRoute>
        <ExerciseType.DeleteExerciseType />
      </ProtectedRoute>
    ),
    isOutlook: true,
  },
  {
    path: "/view-exercise-type",
    element: (
      <ProtectedRoute>
        <ExerciseType.ViewExerciseType />
      </ProtectedRoute>
    ),
    isOutlook: true,
  },
  {
    path: "/create-section",
    element: (
      <ProtectedRoute>
        <Section.CreateSection />
      </ProtectedRoute>
    ),
    isOutlook: true,
  },
  {
    path: "/update-section",
    element: (
      <ProtectedRoute>
        <Section.UpdateSection />
      </ProtectedRoute>
    ),
    isOutlook: true,
  },
  {
    path: "/delete-section",
    element: (
      <ProtectedRoute>
        <Section.DeleteSection />
      </ProtectedRoute>
    ),
    isOutlook: true,
  },
  {
    path: "/view-section",
    element: (
      <ProtectedRoute>
        <Section.ViewSection />
      </ProtectedRoute>
    ),
    isOutlook: true,
  },
  {
    path: "/exercise-section",
    element: (
      <ProtectedRoute>
        <Section.ExerciseSection />
      </ProtectedRoute>
    ),
    isOutlook: true,
  },
  {
    path: "/create-question",
    element: (
      <ProtectedRoute>
        <Question.CreateQuestion />
      </ProtectedRoute>
    ),
    isOutlook: true,
  },
  {
    path: "/update-question",
    element: (
      <ProtectedRoute>
        <Question.UpdateQuestion />
      </ProtectedRoute>
    ),
    isOutlook: true,
  },
  {
    path: "/delete-question",
    element: (
      <ProtectedRoute>
        <Question.DeleteQuestion />
      </ProtectedRoute>
    ),
    isOutlook: true,
  },
  {
    path: "/view-question",
    element: (
      <ProtectedRoute>
        <Question.ViewQuestion />
      </ProtectedRoute>
    ),
    isOutlook: true,
  },

  {
    path: "/create-season",
    element: (
      <ProtectedRoute>
        <Session.CreateSemester />
      </ProtectedRoute>
    ),
    isOutlook: true,
  },
  {
    path: "/update-season",
    element: (
      <ProtectedRoute>
        <Session.UpdateSemester />
      </ProtectedRoute>
    ),
    isOutlook: true,
  },
  {
    path: "/delete-season",
    element: (
      <ProtectedRoute>
        <Session.DeleteSemester />
      </ProtectedRoute>
    ),
    isOutlook: true,
  },
  {
    path: "/view-season",
    element: (
      <ProtectedRoute>
        <Session.ViewSemesters />
      </ProtectedRoute>
    ),
    isOutlook: true,
  },

  {
    path: "/start-accreditation",
    element: (
      <ProtectedRoute>
        <Accreditation.StartAccreditation />
      </ProtectedRoute>
    ),
    isOutlook: true,
  },
  {
    path: "/create-accreditation-panel",
    element: (
      <ProtectedRoute>
        <Accreditation.CreateAccreditation />
      </ProtectedRoute>
    ),
    isOutlook: true,
  },
  // {
  //   path: "/update-accreditation",
  //   element: (
  //     <ProtectedRoute>
  //       <Accreditation.UpdateAccreditation />
  //     </ProtectedRoute>
  //   ),
  //   isOutlook: true,
  // },
  {
    path: "/accreditation-notification",
    element: (
      <ProtectedRoute>
        <Accreditation.AccredicationNotification />
      </ProtectedRoute>
    ),
    isOutlook: true,
  },
  {
    path: "/reaccreditation-exercise",
    element: (
      <ProtectedRoute>
        <Accreditation.ReAccredicationExercise />
      </ProtectedRoute>
    ),
    isOutlook: true,
  },
  {
    path: "/view-ssf-answers",
    element: (
      <ProtectedRoute>
        <SelftStudyForm.ViewSSFAnswers />
      </ProtectedRoute>
    ),
    isOutlook: true,
  },
  {
    path: "/assign-ssf",
    element: (
      <ProtectedRoute>
        <SelftStudyForm.CreateSSF />
      </ProtectedRoute>
    ),
    isOutlook: true,
  },
  {
    path: "/assigned-ssf",
    element: (
      <ProtectedRoute>
        <SelftStudyForm.AssignedSSF />
      </ProtectedRoute>
    ),
    isOutlook: true,
  },
  {
    path: "/change-ssf",
    element: (
      <ProtectedRoute>
        <SelftStudyForm.ChangeSSF />
      </ProtectedRoute>
    ),
    isOutlook: true,
  },
  {
    path: "/widthdraw-ssf",
    element: (
      <ProtectedRoute>
        <SelftStudyForm.WidthdrawSSF />
      </ProtectedRoute>
    ),
    isOutlook: true,
  },
  {
    path: "/signout",
    element: (
      <ProtectedRoute>
        <Signout />
      </ProtectedRoute>
    ),
    isOutlook: false,
  },
];

export { routes, LoginPage, Error404 };
