import React from "react";
import { MaterialReactTable } from "material-react-table";
import ClipLoader from "react-spinners/ClipLoader";
import SwalNotification from "../../functions/SwalNotification";
import HttpRequest from "../../functions/HttpRequest";
import ActivateItem from "../../components/ActivateItem";

const ViewSection = () => {
  const [loading, setloading] = React.useState(false);
  const [data, setData] = React.useState(null);

  React.useEffect(() => {
    (async () => {
      try {
        setloading(true);
        const req = await HttpRequest(`/nuc_sections`, {
          method: "GET",
        });
        if (req.type) {
          SwalNotification(req.type, req.message, "CREATE UNIVERSITY");
          return;
        }
        setData(req);
        setloading(false);
      } catch ({ message }) {
        SwalNotification("error", message, "System Error");
        setloading(false);
      }
    })();
  }, []);
  React.useEffect(() => {
    setTimeout(() => setloading(false), 5000);
  }, []);

  const columns = [
    {
      accessorFn: (row) => `${row?.exerciseID?.title}`,
      header: "Exercise Title",
    },
    {
      accessorKey: "title",
      header: "Exercise Title",
    },
    {
      accessorKey: "description",
      header: "Exercise Description",
    },
    {
      accessorFn: (row) => <ActivateItem row={row} link="nuc_sections/execise" />,
      header: "Status",
    },
    {
      accessorKey: "updatedAt",
      header: "Date & Time",
    },
  ];

  return (
    <div className="mx-auto max-w-7xl px-8 pt-4">
      <h2 className="text-4xl my-5 uppercase">View Question Section</h2>
      <div className="grid grid-cols-1 gap-x-8 gap-y-10 py-2">
        {loading ? (
          <ClipLoader size={35} color={"#36D7B7"} />
        ) : (
          <div className="TheTable">{data && <MaterialReactTable columns={columns} data={data} enableRowNumbers />}</div>
        )}
      </div>
    </div>
  );
};

export default ViewSection;
