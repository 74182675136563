import React from "react";
import { MaterialReactTable } from "material-react-table";
import ClipLoader from "react-spinners/ClipLoader";
import SelectField from "../../components/SelectField";
import HttpRequest from "../../functions/HttpRequest";
import SwalNotification from "../../functions/SwalNotification";
import is_null from "../../functions/is_null";
import ActivateItem from "../../components/ActivateItem";

const ViewDepartment = () => {
  const [loading, setloading] = React.useState(false);
  const [loadingTable, setloadingTable] = React.useState(false);
  const [data, setdata] = React.useState(null);
  const [dataCollge, setdataCollge] = React.useState(null);
  const [picked, setpicked] = React.useState(null);
  const [collegeList, setcollegeList] = React.useState(null);
  const [pickedCollege, setpickedCollege] = React.useState(null);
  const [dataDepartment, setdataDepartment] = React.useState(null);
  React.useEffect(() => {
    (async () => {
      try {
        setloading(true);
        const req = await HttpRequest(`/nuc_institutions/actives`, {
          method: "GET",
        });
        if (req.type) {
          SwalNotification(req.type, req.message, "CREATE UNIVERSITY");
          return;
        }
        const op = req?.map((r) => ({ value: r?._id, label: r?.name }));
        setdata(op);
        setloading(false);
      } catch ({ message }) {
        SwalNotification("error", message, "System Error");
        setloading(false);
      }
    })();
  }, []);
  React.useEffect(() => {
    if (!is_null(picked)) {
      (async () => {
        try {
          const req = await HttpRequest(`/nuc_colleges/university/${picked.value}`, {
            method: "GET",
          });
          if (req.type) {
            SwalNotification(req.type, req.message, "CREATE UNIVERSITY");
            return;
          }
          const op = req?.map((r) => ({ value: r?._id, label: r?.name }));
          setcollegeList(op);
          setdataCollge(req);
        } catch ({ message }) {
          SwalNotification("error", message, "System Error");
          setloading(false);
        }
      })();
    }
  }, [picked]);
  React.useEffect(() => {
    if (!is_null(pickedCollege)) {
      (async () => {
        try {
          setloadingTable(true);
          const req = await HttpRequest(`/nuc_colleges/departments/${pickedCollege._id}`, {
            method: "POST",
          });
          if (req.type) {
            SwalNotification(req.type, req.message, "CREATE UNIVERSITY");
            return;
          }
          setdataDepartment(req);
          setloadingTable(false);
        } catch ({ message }) {
          SwalNotification("error", message, "System Error");
          setloadingTable(false);
        }
      })();
    }
  }, [pickedCollege]);
  const columns = [
    {
      accessorKey: "name",
      header: "Department Name",
    },

    {
      accessorKey: "description",
      header: "Description",
    },
    {
      accessorFn: (row) => <ActivateItem row={row} link="nuc_departments/active" />,
      header: "Status",
    },
    {
      accessorKey: "updatedAt",
      header: "Date & Time",
    },
  ];
  return (
    <div className="mx-auto max-w-7xl px-8 pt-4">
      <h2 className="text-4xl my-5 uppercase">View Departments</h2>
      <div className="grid grid-cols-1 gap-x-8 gap-y-10 py-2">
        <form>
          {loading ? (
            <div>Loading...</div>
          ) : (
            <div className="grid grid-cols-2 gap-x-8 gap-y-10 py-2">
              <SelectField
                name={"university"}
                label={"Pick a university"}
                options={data}
                setValues={(r) => {
                  const { value } = r;
                  const filter = data?.filter((f) => f.value === value).shift();
                  setpicked(filter);
                }}
              />
              {collegeList && (
                <SelectField
                  name={"college"}
                  label={"Pick a college"}
                  options={collegeList}
                  setValues={(r) => {
                    const { value } = r;
                    const filter = dataCollge?.filter((f) => f._id === value).shift();
                    setpickedCollege(filter);
                  }}
                />
              )}
            </div>
          )}
        </form>
        {loadingTable ? (
          <ClipLoader size={35} color={"#36D7B7"} />
        ) : (
          <div className="TheTable">
            {dataDepartment && <MaterialReactTable columns={columns} data={dataDepartment} enableRowNumbers initialState={{ density: "compact" }} />}
          </div>
        )}
      </div>
    </div>
  );
};

export default ViewDepartment;
