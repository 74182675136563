import { GridView } from "@mui/icons-material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose, faQrcode } from "@fortawesome/free-solid-svg-icons";
import { IconButton } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import ReactModal from "react-modal";
import is_null from "../../functions/is_null";
import PositiveOptionShow from "./PositiveOptionShow";
import NegativeOptionShow from "./NegativeOptionShow";

const QuestionDetails = ({ row }) => {
  const customStyles = {
    overlay: {
      zIndex: 1000,
    },
    content: {
      top: "50%",
      left: "50%",
      minWidth: "50%",
      right: "auto",
      zIndex: 1001,
      textTransform: "uppercase",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };
  const [isOpen, setIsOpen] = React.useState(false);
  return (
    <div>
      <ReactModal style={customStyles} isOpen={isOpen}>
        <div className="grid grid-cols-2 gap-4 gap-y-10 py-2">
          <h2>Question Details</h2>
          <button className="text-right" onClick={() => setIsOpen(false)}>
            <FontAwesomeIcon color="red" icon={faClose} />
          </button>
        </div>
        <hr />
        <div className="grid grid-cols-1 gap-x-8 gap-y-3 py-1">
          <div>
            Exercise Type: <b>{!is_null(row?.exerciseID) && row?.exerciseID?.title}</b>
          </div>
          <div>
            Question Section: <b>{!is_null(row?.sectionID) && row?.sectionID?.title}</b>
          </div>
          <div>
            Question Serial: <b>{!is_null(row?.questionSerial) && row?.questionSerial}</b>
          </div>
          <div>
            Question: <b>{!is_null(row?.question) && row?.question}</b>
          </div>
          <div>
            Question Status: <b>{row?.status ? "Active" : "Inactive"}</b>
          </div>
          <div>
            Is Visible: <b>{row?.isVisible ? "Yes" : "No"}</b>
          </div>
          <div>
            {row?.type && row?.type >= 3 && (
              <>
                Options:
                <div className="grid  grid-cols-2 gap-x-8 gap-y-10 py-2">
                  <div className="text-black">
                    <PositiveOptionShow questionType={row?.type} />
                  </div>
                  <div className="text-black">
                    <NegativeOptionShow questionType={row?.type} />
                  </div>
                </div>
              </>
            )}
          </div>
          <div className="grid grid-cols-2 gap-x-8 gap-y-3 py-1">
            <div className="grid grid-cols-1 gap-x-8 gap-y-3 py-1">
              <div>
                Is Attachment Required: <b>{row?.isAttachement ? "Yes" : "No"}</b>
              </div>
              <div>
                Is Geo Location Required: <b>{row?.isGeoLocationRequired ? "Yes" : "No"}</b>
              </div>
              <div>
                Is Photo Required: <b>{row?.isPhotoRequired ? "Yes" : "No"}</b>
              </div>
              <div>
                Is Video Required: <b>{row?.isVideoRequired ? "Yes" : "No"}</b>
              </div>
            </div>
            <div className="grid grid-cols-1 gap-x-8 gap-y-3 py-1">
              <div>
                Is QR-Code Scan Required: <b>{row?.isQrScanRequired === true ? "Yes" : "No"}</b>
              </div>
              <div>
                QR-Code Scan Quantity: <b>{row?.QrScanQuantity}</b>
              </div>
              <div>
                Is Answer Required: <b>{row?.isRequired === true ? "Yes" : "No"}</b>
              </div>
              <div>
                Is Template Available?:{" "}
                <b>
                  {!is_null(row?.template) ? (
                    <Link target="_blank" rel="noopener noreferrer" to={row?.template?.link}>
                      Download TEMPLATE
                    </Link>
                  ) : (
                    "No"
                  )}
                </b>
              </div>
            </div>
          </div>
        </div>
      </ReactModal>
      <IconButton
        onClick={() => {
          console.log(row);
          setIsOpen(true);
        }}
        aria-label="approve"
        className="cursor-pointer"
        color="success"
        style={{ cursor: "pointer" }}
      >
        <GridView />
      </IconButton>
    </div>
  );
};

export default QuestionDetails;
