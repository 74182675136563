import ViewCollegeDepartments from "./CollegeDepartments";
import CreateCollege from "./CreateCollege";
import DeleteCollege from "./DeleteCollege";
import ProposeCreateCollege from "./ProposeCreateCollege";
import ProposeDeleteCollege from "./ProposeDeleteCollege";
import ProposeUpdateCollege from "./ProposeUpdateCollege";
import UpdateCollege from "./UpdateCollege";
import ViewColleges from "./ViewCollege";

export default {
  ViewCollegeDepartments,
  CreateCollege,
  DeleteCollege,
  ProposeCreateCollege,
  ProposeDeleteCollege,
  ProposeUpdateCollege,
  UpdateCollege,
  ViewColleges,
};
