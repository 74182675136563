import React from "react";
import CanvasJSReact from "@canvasjs/react-charts";

const AccreditationChart = () => {
  //var CanvasJS = CanvasJSReact.CanvasJS;
  var CanvasJSChart = CanvasJSReact.CanvasJSChart;
  var chart = {
    title: {
      text: "Total Accreditations for Year 2024",
    },
    data: [
      {
        type: "column",
        dataPoints: [
          { label: "January", y: 10 },
          { label: "February", y: 15 },
          { label: "March", y: 25 },
          { label: "April", y: 30 },
          { label: "May", y: 28 },
          { label: "June", y: 34 },
          { label: "July", y: 59 },
          { label: "August", y: 30 },
          { label: "September", y: 10 },
          { label: "October", y: 56 },
          { label: "November", y: 12 },
          { label: "December", y: 8 },
        ],
      },
    ],
  };

  return <CanvasJSChart options={chart} style={{ zIndex: 0 }} />;
};

export default AccreditationChart;
