import React from "react";
import { logoutUser } from "../../service/authService";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const Signout = () => {
  const [loading, setloading] = React.useState(true);
  const navigate = useNavigate();
  React.useEffect(() => {
    (async () => {
      try {
        const logout = await logoutUser();
        if (logout) {
          navigate("/");
        } else {
          throw new Error("Logout failed");
        }
      } catch ({ message }) {
        toast.error(message);
      }
    })();
  }, []);
  return (
    <div>
      {loading
        ? "Please wait system signing out..."
        : "Completed Signout procedures"}
    </div>
  );
};

export default Signout;
