import { Close } from "@mui/icons-material";
import React from "react";
import ReactModal from "react-modal";
import is_null from "../functions/is_null";
import NotifyNow from "./NotifyNow";
import AutoAccept from "./AutoAccept";
import SwalNotification from "../functions/SwalNotification";
import HttpRequest from "../functions/HttpRequest";

const PanelMembers = ({ info, row, univ, prog }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const customStyles = {
    overlay: {
      zIndex: 1000,
    },
    content: {
      top: "50%",
      left: "50%",
      minWidth: "75vw",
      right: "auto",
      zIndex: 1001,
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };
  const _continue = async () => {
    try {
      setLoading(true);
      if (!window.confirm("Are you sure you want to allow panels to accreditate?")) {
        return;
      }
      const req = await HttpRequest(`/nuc_programmes/allowpanel`, {
        body: JSON.stringify({ exerciseID: info?._id }),
        method: "POST",
      });
      SwalNotification(req?.type, req?.message, "CREATE UNIVERSITY");
      setLoading(false);
    } catch ({ message }) {
      SwalNotification("error", message, "System Error");
      setLoading(false);
    }
  };
  return (
    <div>
      <ReactModal style={customStyles} isOpen={isOpen} contentLabel="Example Modal">
        <div className="grid grid-cols-2 gap-4 gap-y-10 py-2">
          <h2>
            SELECTED PANEL
            <br /> {prog} <br /> {univ}
          </h2>
          <button onClick={() => setIsOpen(false)} className="text-right" style={{ color: "red" }}>
            <Close color="red" />
          </button>
        </div>
        <div className="grid grid-cols-1 gap-4 gap-y-10 py-2">
          <hr />
        </div>
        <div className="min-w-[100%]">
          {!is_null(row) &&
            row?.map((r, i) => (
              <div
                key={`display_panel_member_${i}`}
                className="min-w-[100%] block max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:text-white dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700 mt-1 mb-2"
              >
                <div className="flex justify-between">
                  <div>
                    <div>Name: {r.name}</div>
                    <div>
                      Email Address: <span className="lowercase bolder">{r.emailAddress}</span> | Phone Number:{" "}
                      <span className="bolder">{r.phoneNumber}</span>
                    </div>
                    <div>
                      Is Chair: <b style={{ color: `${r.isChair ? "green" : "red"}` }}>{r.isChair ? "Yes" : "No"}</b> | Has accepted:{" "}
                      <b style={{ color: `${r.isAccepted ? "green" : "red"}` }}>{r.isAccepted ? "Yes" : "No"}</b> | Has been notified:{" "}
                      <b style={{ color: `${r.isNotified ? "green" : "red"}` }}>{r.isNotified ? "Yes" : "No"}</b>
                    </div>
                  </div>
                  <div className="flex flex-col">
                    <div className="mt-2">
                      <NotifyNow panel={r} />
                    </div>
                    <div className="mt-2">
                      <AutoAccept panel={r} />
                    </div>
                  </div>
                </div>
              </div>
            ))}
          <div className="flex justify-end">
            <button
              disabled={loading}
              onClick={_continue}
              className="uppercase bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded-lg focus:outline-none focus:bg-green-600"
            >
              {loading ? "Allowing Panel to Start Accreditation" : "Allow Panel to Start Accreditation"}
            </button>
          </div>
        </div>
      </ReactModal>

      <button
        onClick={() => setIsOpen(true)}
        className="w-full bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded-lg focus:outline-none focus:bg-green-600"
      >
        {row?.length} Members
      </button>
    </div>
  );
};

export default PanelMembers;
