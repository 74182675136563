import React from "react";
import { MaterialReactTable } from "material-react-table";
import ClipLoader from "react-spinners/ClipLoader";
import { IconButton } from "@mui/material";
import { DeleteForever, ThumbsUpDownOutlined } from "@mui/icons-material";

const ProposeDeleteCollege = () => {
  const [loading, setloading] = React.useState(true);
  React.useEffect(() => {
    setTimeout(() => {
      setloading(false);
    }, 5000);
  }, []);
  const columns = [
    {
      accessorKey: "university",
      header: "University Name",
    },
    {
      accessorKey: "oldname",
      header: "Name",
    },
    {
      accessorKey: "updatedAt",
      header: "Date & Time",
    },
    {
      accessorFn: (row) => (
        <IconButton aria-label="approve" className="cursor-pointer" color="success" style={{ cursor: "pointer" }}>
          <ThumbsUpDownOutlined />
        </IconButton>
      ),
      header: "Approve",
    },
    {
      accessorFn: (row) => (
        <IconButton aria-label="delete" className="cursor-pointer" color="primary" style={{ cursor: "pointer" }}>
          <DeleteForever />
        </IconButton>
      ),
      header: "Reject",
    },
  ];
  const data = [
    {
      oldname: "College of Natural & Applied Sciences",
      university: "Al-Kanemi Heritage University",
      newname: "College of Computing",
      updatedAt: new Date().toISOString(),
    },
  ];
  return (
    <div className="mx-auto max-w-7xl px-8 pt-4" style={{ zIndex: -10 }}>
      <h2 className="text-4xl my-5 uppercase">Proposed Delete College</h2>
      <div className="grid grid-cols-1 gap-x-8 gap-y-10 py-2" style={{ zIndex: -10 }}>
        <>
          {loading ? (
            <ClipLoader size={35} color={"#36D7B7"} />
          ) : (
            <div className="TheTable">
              <MaterialReactTable columns={columns} data={data} enableRowNumbers initialState={{ density: "compact" }} />
            </div>
          )}
        </>
      </div>
    </div>
  );
};

export default ProposeDeleteCollege;
