import React from "react";
import ClipLoader from "react-spinners/ClipLoader";
import SelectField from "../../components/SelectField";
import HttpRequest from "../../functions/HttpRequest";
import SwalNotification from "../../functions/SwalNotification";
import is_null from "../../functions/is_null";

const DeleteUniversity = () => {
  const [deletes, setdeletes] = React.useState(false);
  const [loading, setloading] = React.useState(false);
  const [data, setdata] = React.useState(null);
  const [picked, setpicked] = React.useState(null);
  const [options, setoptions] = React.useState(null);

  React.useEffect(() => {
    (async () => {
      try {
        setloading(true);
        const req = await HttpRequest(`/nuc_institutions/actives`, {
          method: "GET",
        });
        if (req.type) {
          SwalNotification(req.type, req.message, "CREATE UNIVERSITY");
          return;
        }
        const op = req?.map((r) => ({ value: r?._id, label: r?.name }));
        setdata(req);
        setoptions(op);
        setloading(false);
      } catch ({ message }) {
        SwalNotification("error", message, "System Error");
        setloading(false);
      }
    })();
  }, []);

  const _handleSubmit = async (e) => {
    try {
      e.preventDefault();
      const fd = new FormData(e.target);
      setdeletes(true);
      if (is_null(fd.get("university"))) {
        throw new Error("Please select a university");
      }
      const req = await HttpRequest(`/nuc_institutions/${fd.get("university")}`, {
        method: "DELETE",
      });
      SwalNotification(req.type, req.message, "CREATE UNIVERSITY");
      setdeletes(false);
    } catch ({ message }) {
      SwalNotification("error", `An error occurred. Err: ${message}`);
      setdeletes(false);
    }
  };

  return (
    <div className="mx-auto max-w-7xl px-8 pt-4">
      <h2 className="text-4xl my-5 uppercase">Delete University {picked && picked?.name}...</h2>
      <div className="grid grid-cols-1 gap-x-8 gap-y-10 py-2">
        {loading ? (
          <ClipLoader size={35} color={"#36D7B7"} />
        ) : (
          <>
            <form onSubmit={_handleSubmit}>
              <SelectField
                name={"university"}
                label={"Pick a university"}
                options={options}
                onChange={(r) => {
                  const { value } = r;
                  const filter = data?.filter((f) => f._id === value).shift();
                  setpicked(filter);
                }}
              />
              <div className="flex items-center justify-center mb-4">{deletes && <ClipLoader size={35} color={"#36D7B7"} />}</div>
              <button
                type="submit"
                disabled={deletes}
                className="w-full bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded-lg focus:outline-none focus:bg-green-600"
              >
                {deletes ? "Deleting University..." : "Delete University"}
              </button>
            </form>
          </>
        )}
      </div>
    </div>
  );
};

export default DeleteUniversity;
