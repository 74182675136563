import React, { useState } from "react";
import { MaterialReactTable } from "material-react-table";
import ClipLoader from "react-spinners/ClipLoader";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import HttpRequest from "../../functions/HttpRequest";
import Modal from "../../components/common/modal";
import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/24/solid";

const ProposeCreateCollege = () => {
	const [isModalOpen, setModalOpen] = useState(false);
	const [rejectReason, setRejectReason] = useState("");
	const [selectedId, setSelectedId] = useState(null);

	const queryClient = useQueryClient();

	const openModal = (id) => {
		setSelectedId(id);
		setModalOpen(true);
	};

	const closeModal = () => {
		setModalOpen(false);
		setRejectReason("");
	};

	// Fetching colleges data
	const { data: colleges = [], isLoading } = useQuery({
		queryKey: ["institutions"],
		queryFn: async () => {
			const res = await HttpRequest(`/ti_institution`, {
				method: "GET",
			});
			return res;
		},
	});

	const handleReject = useMutation({
		mutationFn: async ({ id, reason }) => {
			const res = await HttpRequest(`/ti_institution/reason/reject`, {
				body: JSON.stringify({ id, reason }),
				method: "PUT",
				headers: { "Content-type": "application/json" },
			});
			return res;
		},
		onSuccess: () => {
			queryClient.invalidateQueries("institutions");
			closeModal();
		},
	});

	const handleApprove = useMutation({
		mutationFn: async (id) => {
			const res = await HttpRequest(`/ti_institution/approve/${id}`, {
				method: "PUT",
				headers: { "Content-type": "application/json" },
			});
			return res;
		},
		onSuccess: () => {
			queryClient.invalidateQueries("institutions");
		},
	});

	const columns = [
		{
			accessorKey: "name",
			header: "University Name",
		},
		{
			accessorKey: "address",
			header: "Univrsity Address",
		},
		{
			accessorKey: "updatedAt",
			header: "Date & Time",
		},
		{
			accessorFn: (row) => (
				<CheckCircleIcon
					key={`approve-${row._id}`}
					aria-label="approve"
					className="cursor-pointer h-6 w-6 text-green-600"
					onClick={() => handleApprove.mutate(row._id)} // Pass the row._id to the mutate function
				/>
			),
			header: "Approve",
		},
		{
			accessorFn: (row) => (
				<XCircleIcon
					key={`reject-${row._id}`}
					aria-label="reject"
					className="cursor-pointer h-6 w-6 text-red-600"
					onClick={() => openModal(row._id)}
				/>
			),
			header: "Reject",
		},
	];

	return (
		<>
			<div className="mx-auto max-w-7xl px-8 pt-4">
				<h2 className="text-4xl my-5 uppercase">Proposed Create Institution</h2>
				<div className="grid grid-cols-1 gap-x-8 gap-y-10 py-2">
					{isLoading ? (
						<ClipLoader size={35} color={"#36D7B7"} />
					) : (
						<div className="TheTable">
							<MaterialReactTable
								columns={columns}
								data={colleges}
								enableRowNumbers
								initialState={{ density: "compact" }}
							/>
						</div>
					)}
				</div>
			</div>
			<Modal isOpen={isModalOpen} onClose={closeModal} title="Reject College">
				<textarea
					value={rejectReason}
					onChange={(e) => setRejectReason(e.target.value)}
					className="w-full h-20 p-2 border border-gray-300 rounded"
					placeholder="Enter reason for rejection"
				/>
				<div className="flex gap-4 my-5">
					<button
						onClick={closeModal}
						className="px-4 py-2 bg-gray-500 text-white rounded-lg hover:bg-gray-600"
					>
						Cancel
					</button>
					<button
						onClick={() =>
							handleReject.mutate({
								id: selectedId,
								reason: rejectReason,
							})
						}
						className="px-4 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600"
					>
						Reject
					</button>
				</div>
			</Modal>
		</>
	);
};

export default ProposeCreateCollege;
