import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faSpinner } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import HttpRequest from "../functions/HttpRequest";
import SwalNotification from "../functions/SwalNotification";

const ActivateItem = ({ row, link }) => {
  const [loading, setloading] = React.useState(false);
  const [status, setstatus] = React.useState(false);
  const _activate = async () => {
    try {
      setloading(true);
      const req = await HttpRequest(`/${link}/${row._id}`, {
        method: "PUT",
      });
      SwalNotification(req.type, req.message, "CREATE UNIVERSITY");
      if (req.type === "success") {
        setstatus(true);
      }
      setloading(false);
    } catch ({ message }) {
      SwalNotification("error", message, "CREATE UNIVERSITY");
      setloading(false);
    }
  };
  return (
    <>
      {row.status || status ? (
        "Active"
      ) : (
        <button
          className="relative align-middle select-none font-sans font-medium text-center uppercase transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none w-10 max-w-[40px] h-10 max-h-[40px] rounded-lg text-xs bg-green-900 text-white shadow-md shadow-green-900/10 hover:shadow-lg hover:shadow-gray-900/20 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none"
          type="button"
          onClick={_activate}
        >
          <span className="absolute transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">
            <FontAwesomeIcon icon={!loading ? faCheckCircle : faSpinner} spin={!loading ? false : true} size="2x" />
          </span>
        </button>
      )}
    </>
  );
};

export default ActivateItem;
