import React, { useState, useEffect } from "react";
import { MaterialReactTable } from "material-react-table";
import ClipLoader from "react-spinners/ClipLoader";
import SelectField from "../../components/SelectField";
import HttpRequest from "../../functions/HttpRequest";
import SwalNotification from "../../functions/SwalNotification";
import is_null from "../../functions/is_null";
import QuestionDetails from "./QuestionDetails";

const ViewQuestion = () => {
  const [loading, setLoading] = useState(false);
  const [isExercise, setExercise] = useState(null);
  const [isOptions, setOptions] = useState(null);
  const [data, setData] = useState([]);
  const [isPickedExercise, setPickedExercise] = useState(null);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const req = await HttpRequest(`/nuc_exercise_type`, { method: "GET" });
        if (req.type) {
          SwalNotification(req.type, req.message, "CREATE UNIVERSITY");
          return;
        }
        const op = req?.map((r) => ({ value: r?._id, label: r?.title }));
        setOptions(op);
        setExercise(req);
        setLoading(false);
      } catch ({ message }) {
        SwalNotification("error", message, "System Error");
        setLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    if (!is_null(isPickedExercise)) {
      (async () => {
        try {
          const req = await HttpRequest(`/nuc_questions/exercise/${isPickedExercise._id}`, { method: "GET" });
          if (!is_null(req?.type)) {
            SwalNotification(req.type, req.message, "CREATE UNIVERSITY");
            return;
          }
          setData(req);
        } catch ({ message }) {
          SwalNotification("error", message, "System Error");
          setLoading(false);
        }
      })();
    }
  }, [isPickedExercise]);

  const columns = [
    {
      accessorFn: (row) => `${row?.sectionID?.title}`,
      header: "Section Title",
    },
    {
      accessorKey: "question",
      header: "Question",
    },
    {
      accessorFn: (row) => `${row?.isVisible ? "Yes" : "No"}`,
      header: "isVisible",
    },
    {
      accessorFn: (row) => <QuestionDetails row={row} />,
      header: "View Details",
    },
    {
      accessorKey: "updatedAt",
      header: "Date & Time",
    },
  ];

  return (
    <>
      <div className="mx-auto max-w-12xl px-8 pt-4">
        <h2 className="text-4xl my-5 uppercase">View Questions</h2>
        <div className="grid grid-cols-1 gap-x-8 gap-y-10 py-2">
          <div className="grid grid-cols-1 gap-x-8 gap-y-10 py-2">
            {loading ? (
              <ClipLoader size={35} color={"#36D7B7"} />
            ) : (
              <>
                {isOptions && (
                  <SelectField
                    name={"exerciseID"}
                    label={"Pick an exercise type"}
                    required
                    options={isOptions}
                    setValues={(r) => {
                      const { value } = r;
                      const filter = isExercise?.filter((f) => f._id === value).shift();
                      setPickedExercise(filter);
                    }}
                  />
                )}
              </>
            )}
          </div>
          {loading ? (
            <ClipLoader size={35} color={"#36D7B7"} />
          ) : (
            <div className="TheTable">
              <MaterialReactTable columns={columns} data={data} enableRowNumbers />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ViewQuestion;
