import React from "react";
import ClipLoader from "react-spinners/ClipLoader";
import TextAreaField from "../../components/TextAreaField";
import SelectField from "../../components/SelectField";
import SwalNotification from "../../functions/SwalNotification";
import HttpRequest from "../../functions/HttpRequest";
import is_null from "../../functions/is_null";
import InputField from "../../components/InputField";

const UpdateCollege = () => {
	const [pickedCollege, setpickedCollege] = React.useState(null);
	const [picked, setpicked] = React.useState(null);
	const [collegeList, setcollegeList] = React.useState(null);
	const [loading, setloading] = React.useState(false);
	const [loadingSave, setloadingSave] = React.useState(false);
	const [data, setdata] = React.useState(null);
	const [dataCollge, setdataCollge] = React.useState(null);
	React.useEffect(() => {
		(async () => {
			try {
				setloading(true);
				const req = await HttpRequest(`/nuc_institutions/actives`, {
					method: "GET",
				});
				if (req.type) {
					SwalNotification(req.type, req.message, "CREATE UNIVERSITY");
					return;
				}
				const op = req?.map((r) => ({ value: r?._id, label: r?.name }));
				setdata(op);
				setloading(false);
			} catch ({ message }) {
				SwalNotification("error", message, "System Error");
				setloading(false);
			}
		})();
	}, []);

	React.useEffect(() => {
		if (!is_null(picked)) {
			(async () => {
				try {
					const req = await HttpRequest(
						`/nuc_colleges/university/${picked.value}`,
						{
							method: "GET",
						}
					);
					if (req.type) {
						SwalNotification(req.type, req.message, "CREATE UNIVERSITY");
						return;
					}
					const op = req?.map((r) => ({ value: r?._id, label: r?.name }));
					setcollegeList(op);
					setdataCollge(req);
				} catch ({ message }) {
					SwalNotification("error", message, "System Error");
					// setloading(false);
				}
			})();
		}
	}, [picked]);
	const handleSubmit = async (e) => {
		try {
			e.preventDefault();
			const fd = new FormData(e.target);
			setloadingSave(true);
			const university = fd.get("university");
			const college = fd.get("college");
			const name = fd.get("name");
			const description = fd.get("description");
			if (
				is_null(university) ||
				is_null(college) ||
				is_null(name) ||
				is_null(description)
			) {
				throw new Error("Please fill the form properly");
			}
			const req = await HttpRequest(`/nuc_colleges/${college}`, {
				method: "PUT",
				body: JSON.stringify({ university, college, name, description }),
			});
			SwalNotification(req.type, req.message, "System Error");
			setloadingSave(false);
		} catch ({ message }) {
			SwalNotification("error", message, "System Error");
			setloadingSave(false);
		}
	};
	//
	return (
		<div className="mx-auto max-w-7xl px-8 pt-4">
			<h2 className="text-4xl my-5 uppercase">Update College...</h2>
			<div className="grid grid-cols-1 gap-x-8 gap-y-10 py-2">
				<form onSubmit={handleSubmit}>
					{loading ? (
						<div>Loading...</div>
					) : (
						<SelectField
							name={"university"}
							label={"Pick a university"}
							options={data}
							setValues={(r) => {
								const { value } = r;
								const filter = data?.filter((f) => f.value === value).shift();
								setpicked(filter);
							}}
						/>
					)}
					{collegeList && (
						<SelectField
							name={"college"}
							label={"Pick a college"}
							options={collegeList}
							setValues={(r) => {
								const { value } = r;
								const filter = dataCollge
									?.filter((f) => f._id === value)
									.shift();
								setpickedCollege(filter);
							}}
						/>
					)}

					<InputField
						label={`Enter College/Faculty/School Name`}
						placeholder={`Enter College/Faculty/School Name`}
						required
						name={`name`}
						defaultValue={pickedCollege ? pickedCollege.name : ""}
					/>

					<TextAreaField
						label={`Enter College/Faculty/School Description`}
						placeholder={`Enter College/Faculty/School Description`}
						required
						name={`description`}
						defaultValue={pickedCollege ? pickedCollege.description : ""}
					/>
					<div className="flex items-center justify-center mb-4">
						{loadingSave && <ClipLoader size={35} color={"#36D7B7"} />}
					</div>
					<button
						type="submit"
						disabled={loadingSave}
						className="w-full bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded-lg focus:outline-none focus:bg-green-600"
					>
						{loadingSave ? "Updating College..." : "Update College"}
					</button>
				</form>
			</div>
		</div>
	);
};

export default UpdateCollege;
