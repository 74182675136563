import React from "react";
import ClipLoader from "react-spinners/ClipLoader";
import InputField from "../../components/InputField";
import TextAreaField from "../../components/TextAreaField";
import is_null from "../../functions/is_null";
import SwalNotification from "../../functions/SwalNotification";
import HttpRequest from "../../functions/HttpRequest";

const CreateExerciseType = () => {
  const [isSubmitting, setSubmitting] = React.useState(false);
  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      setSubmitting(true);
      const fd = new FormData(e.target);
      const title = fd.get("title");
      const description = fd.get("description");
      if (is_null(title) || is_null(description)) {
        throw new Error("Please fill the form properly");
      }
      const req = await HttpRequest(`/nuc_exercise_type`, {
        body: JSON.stringify({ title, description }),
        method: "POST",
      });
      SwalNotification(req?.type, req?.message, "CREATE UNIVERSITY");

      setSubmitting(false);
    } catch ({ message }) {
      SwalNotification("error", message, "SYSTEM ERROR");
      setSubmitting(false);
    }
  };
  return (
    <div className="mx-auto max-w-7xl px-8 pt-4">
      <h2 className="text-4xl my-5 uppercase">Create Exercise Type...</h2>
      <div className="grid grid-cols-1 gap-x-8 gap-y-10 py-2">
        <form onSubmit={handleSubmit}>
          <InputField name="title" label="Enter title" />
          <TextAreaField name="description" label="Enter Description" />
          <div className="flex items-center justify-center mb-4">{isSubmitting && <ClipLoader size={35} color={"#36D7B7"} />}</div>
          <button
            type="submit"
            disabled={isSubmitting}
            className="w-full bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded-lg focus:outline-none focus:bg-green-600"
          >
            {isSubmitting ? "Creating Exercise Type..." : "Create Exercise Type"}
          </button>
        </form>
      </div>
    </div>
  );
};

export default CreateExerciseType;
