import React, { useRef } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import InputField from "../../components/InputField";
import TextAreaField from "../../components/TextAreaField";
import SelectField from "../../components/SelectField";
import HttpRequest from "../../functions/HttpRequest";
import SwalNotification from "../../functions/SwalNotification";
import is_null from "../../functions/is_null";
import PositiveOption from "./PositiveOption";
import NegativeOption from "./NegativeOption";
import CheckBox from "../../components/CheckBox";

const CreateQuestion = () => {
  const [isSubmitting, setSubmitting] = React.useState(false);
  const [isOptions, setOptions] = React.useState(null);
  const [isSectionOptions, setSectionOptions] = React.useState(null);
  const [isExercise, setExercise] = React.useState(null);
  const [isSection, setSection] = React.useState(null);
  const [data, setData] = React.useState(null);
  const [sectionQuestions, setSectionQuestions] = React.useState(null);
  const [positive, setPositive] = React.useState(null);
  const [negative, setNegative] = React.useState(null);
  const [questionType, setQuestionType] = React.useState(null);
  const [isPickedExercise, setPickedExercise] = React.useState(null);
  const [loading, setloading] = React.useState(false);
  const isRequiredRef = useRef(null);
  const isPhotoRef = useRef(null);
  const isGeoLocationRef = useRef(null);
  const isVideoRef = useRef(null);
  const isVisileRef = useRef(null);
  const isAttachementRef = useRef(null);
  const isQrScanRequiredRef = useRef(null);

  React.useEffect(() => {
    (async () => {
      try {
        setloading(true);
        const req = await HttpRequest(`/nuc_exercise_type`, {
          method: "GET",
        });
        if (req.type) {
          SwalNotification(req.type, req.message, "CREATE UNIVERSITY");
          return;
        }
        const op = req?.map((r) => ({ value: r?._id, label: r?.title }));
        setOptions(op);
        setExercise(req);
        setloading(false);
      } catch ({ message }) {
        SwalNotification("error", message, "System Error");
        setloading(false);
      }
    })();
  }, []);
  React.useEffect(() => {
    if (!is_null(isPickedExercise)) {
      (async () => {
        try {
          const req = await HttpRequest(`/nuc_sections/execise/${isPickedExercise._id}`, {
            method: "GET",
          });
          if (!is_null(req?.type)) {
            SwalNotification(req.type, req.message, "CREATE UNIVERSITY");
            return;
          }
          const op = req?.map((r) => ({ value: r?._id, label: r?.title }));
          setSectionOptions(op);
          setSection(req);
        } catch ({ message }) {
          SwalNotification("error", message, "System Error");
          setloading(false);
        }
      })();
    }
  }, [isPickedExercise]);
  React.useEffect(() => {
    if (!is_null(questionType) && !is_null(data)) {
      if (questionType > 2) {
        (async () => {
          try {
            const req = await HttpRequest(`/nuc_questions/section/${data._id}`, {
              method: "GET",
            });
            if (!is_null(req?.type)) {
              SwalNotification(req.type, req.message, "CREATE UNIVERSITY");
              return;
            }
            setSectionQuestions(req);
          } catch ({ message }) {
            SwalNotification("error", message, "System Error");
            setloading(false);
          }
        })();
      }
    }
  }, [questionType, data]);
  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      setSubmitting(true);
      if (!is_null(negative) && !is_null(positive)) {
        if (negative === positive) {
          throw new Error("Sorry, follow up questions cannot be the same for both sides");
        }
      }
      const fd = new FormData(e.target);

      const exerciseID = fd.get("exerciseID");
      const sectionID = fd.get("sectionID");
      const questionSerial = fd.get("questionSerial");
      const question = fd.get("question");
      const type = fd.get("type");
      const QrScanQuantity = fd.get("QrScanQuantity");
      const isVisible = isVisileRef.current.checked;
      const isPhotoRequired = isPhotoRef.current.checked;
      const isRequired = isRequiredRef.current.checked;
      const isVideoRequired = isVideoRef.current.checked;
      const isQrScanRequired = isQrScanRequiredRef.current.checked;
      const isAttachement = isAttachementRef.current.checked;
      const isGeoLocationRequired = isGeoLocationRef.current.checked;
      let fileupload = null;
      if (fd.get("template").size) {
        const formData = new FormData();
        formData.append("photo", fd.get("template"));
        const upload = await fetch(`http://localhost/nuc/index.php`, { method: "POST", body: formData, headers: {} });
        fileupload = await upload.json();
      }

      if (
        is_null(isVisible) ||
        is_null(exerciseID) ||
        is_null(sectionID) ||
        is_null(questionSerial) ||
        is_null(question) ||
        is_null(type) ||
        is_null(isRequired) ||
        is_null(isQrScanRequired)
      ) {
        throw new Error("Please make sure compulsory fields have value");
      }
      const req = await HttpRequest(`/nuc_questions`, {
        body: JSON.stringify({
          exerciseID,
          sectionID,
          questionSerial,
          question,
          type,
          isPhotoRequired,
          positiveOption: positive,
          negativeOption: negative,
          isVisible,
          isRequired,
          template: fileupload,
          isAttachement,
          isVideoRequired,
          isQrScanRequired,
          QrScanQuantity,
          isGeoLocationRequired,
        }),
        method: "POST",
      });
      SwalNotification(req?.type, req?.message, "CREATE UNIVERSITY");
      setSubmitting(false);
    } catch ({ message }) {
      SwalNotification("error", message, "CREATE UNIVERSITY");
      setSubmitting(false);
    }
  };
  return (
    <div className="mx-auto max-w-7xl px-8 pt-4">
      <h2 className="text-4xl my-5 uppercase">Create Question...</h2>
      <div className="grid grid-cols-1 gap-x-8 gap-y-10 py-2">
        <form onSubmit={handleSubmit}>
          <div className="grid grid-cols-2 gap-x-8 gap-y-10 py-2">
            <div>
              {isOptions && (
                <SelectField
                  name={"exerciseID"}
                  label={"Pick a exercise type"}
                  required
                  options={isOptions}
                  setValues={(r) => {
                    const { value } = r;
                    const filter = isExercise?.filter((f) => f._id === value).shift();
                    setPickedExercise(filter);
                  }}
                />
              )}
            </div>

            <div>
              {isSection && (
                <SelectField
                  name={"sectionID"}
                  label={"Pick a section"}
                  required
                  options={isSectionOptions}
                  setValues={(r) => {
                    const { value } = r;
                    const filter = isSection?.filter((f) => f._id === value).shift();
                    setData(filter);
                  }}
                />
              )}
            </div>
          </div>
          <div className="grid grid-cols-2 gap-x-8 gap-y-10 py-2">
            <div>
              <SelectField
                name={"type"}
                label={"Pick a Question Type"}
                required
                options={[
                  { value: 1, label: "Short Answer" },
                  { value: 2, label: "Long Answer" },
                  { value: 3, label: "Yes or No" },
                  { value: 4, label: "True or False" },
                ]}
                setValues={(e) => {
                  setQuestionType(e?.value);
                }}
              />
            </div>

            <div>
              <InputField required name={"questionSerial"} type="text" label={`Question Serial Number`} placeholder={`Question Serial Number`} />
            </div>
          </div>
          <TextAreaField name="question" label="Enter Question" placeholder="Enter Question" row={4} />
          {questionType && questionType >= 3 && (
            <div className="grid grid-cols-2 gap-x-8 gap-y-10 py-2">
              <PositiveOption setPositive={setPositive} questionType={questionType} info={sectionQuestions} />
              <NegativeOption setNegative={setNegative} questionType={questionType} info={sectionQuestions} />
            </div>
          )}
          <div className="grid grid-cols-3 gap-x-8 gap-y-10 py-2">
            <CheckBox type="checkbox" ref={isPhotoRef} label={"Is Photo Required"} placeholder={"Is Photo Required"} />
            <CheckBox
              type="checkbox"
              ref={isGeoLocationRef}
              name="isGeoLocationRequired"
              label={"Is Video Required"}
              placeholder={"Is Video Required"}
            />
            <CheckBox
              type="checkbox"
              ref={isVideoRef}
              value={true}
              name="isVideo"
              label={"Is Geo Location Required"}
              placeholder={"Is Geo Location Required"}
            />
          </div>
          <div className="grid grid-cols-3 gap-x-8 gap-y-10 py-2">
            <CheckBox
              type="checkbox"
              defaultChecked={true}
              value={true}
              ref={isVisileRef}
              name="isVisible"
              label={"Is this question visible or hidden"}
              placeholder={"Is this question visible or hidden"}
            />
            <CheckBox
              type="checkbox"
              value={true}
              name={"isRequired"}
              ref={isRequiredRef}
              label={"Is Answer Required"}
              placeholder={"Is Answer Required"}
            />
            <CheckBox
              type="checkbox"
              ref={isAttachementRef}
              value={true}
              name="isAttachment"
              label={"Is Attachment Required"}
              placeholder={"Is Attachment Required"}
            />
          </div>
          <div className="grid grid-cols-2 gap-x-8 gap-y-10 py-2">
            <CheckBox type="checkbox" name={"isQrScanRequired"} ref={isQrScanRequiredRef} label={"Is QR-Code Scan Required"} value={true} />
            <InputField
              defaultValue={0}
              label={`Quantity of Item to be scanned`}
              placeholder={`Quantity of Item to be scanned`}
              name={"QrScanQuantity"}
            />
          </div>
          <div className="grid grid-cols-1 gap-x-8 gap-y-10 py-2">
            <InputField
              name="template"
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              type="file"
              label={`Upload Expected Template`}
            />
          </div>
          <div className="flex items-center justify-center mb-4">{isSubmitting && <ClipLoader size={35} color={"#36D7B7"} />}</div>
          <button
            type="submit"
            disabled={isSubmitting}
            className="w-full bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded-lg focus:outline-none focus:bg-green-600"
          >
            {isSubmitting ? "Creating Question Sections..." : "Create Question Sections"}
          </button>
        </form>
      </div>
    </div>
  );
};

export default CreateQuestion;
