import { Close } from "@mui/icons-material";
import { UserGroupIcon } from "@heroicons/react/24/outline";
import { IconButton } from "@mui/material";
import React from "react";
import ReactModal from "react-modal";
import is_null from "../../functions/is_null";
import AccreditationPanel from "../../components/AccreditationPanel";
import InputField from "../../components/InputField";
import SwalNotification from "../../functions/SwalNotification";
import HttpRequest from "../../functions/HttpRequest";

const NewAccreditation = ({ row }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [pick, setPick] = React.useState(false);
  const st = !is_null(row?.startDate) ? String(row?.startDate).split("T")[0] : null;
  const nd = !is_null(row?.endDate) ? String(row?.endDate).split("T")[0] : null;
  const [start, setStart] = React.useState(st);
  const [end, setEnd] = React.useState(nd);
  const _continue = async () => {
    try {
      if (!is_null(start) && !is_null(end)) {
        if (new Date(start) >= new Date(end)) {
          SwalNotification("error", "End date cannot be less than or equal to start date", "System Error");
          return;
        }
        const req = await HttpRequest(`/nuc_programmes/ssf/completed`, {
          method: "PUT",
          body: JSON.stringify({ start, end, ssf: row?._id }),
        });
        SwalNotification(req?.type, req?.message, "System Error");
        if (req?.type === "success") {
          setPick(true);
        }
      } else {
        SwalNotification("error", "Values are needed for start date and end date", "System Error");
      }
    } catch ({ message }) {
      SwalNotification("error", message, "System Error");
    }
  };
  const customStyles = {
    overlay: {
      zIndex: 1000,
    },
    content: {
      top: "50%",
      left: "50%",
      minWidth: "75vw",
      maxWidth: "95vw",
      right: "auto",
      zIndex: 1001,
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };
  return (
    <>
      <ReactModal style={customStyles} isOpen={isOpen} contentLabel="Example Modal">
        <div className="grid grid-cols-2 gap-4 gap-y-10 py-2">
          <h2 className="uppercase">
            {row?.programmeID?.name} | {`${row?.institutionID?.name}`}
          </h2>
          <button onClick={() => setIsOpen(false)} className="text-right" style={{ color: "red" }}>
            <Close color="red" />
          </button>
        </div>
        <div className="grid grid-cols-1 gap-4 gap-y-10 py-2">
          <hr />
        </div>
        {!pick && (
          <div className="grid grid-cols-3 gap-4 gap-y-10 py-2">
            <InputField defaultValue={st} onChange={(e) => setStart(e.target.value)} label="Start Date" type="date" name="start_date" />
            <InputField defaultValue={nd} onChange={(e) => setEnd(e.target.value)} label="End Date" type="date" name="end_date" />
            <button
              onClick={_continue}
              className="w-full bg-green-500 hover:bg-green-600 text-white font-bold  max-h-[100px] rounded-lg focus:outline-none focus:bg-green-600"
            >
              Continue
            </button>
          </div>
        )}
        {pick && (
          <div className="w-[100%]">
            <div className="grid grid-cols-3 gap-4 gap-y-10 py-2">
              {!is_null(row?.assignedPanel) &&
                row?.assignedPanel?.map((r, i) => (
                  <div key={`panel_member_${i}`} className="mt-1 mb-2">
                    <div>Name: {r.name}</div>
                    <div>
                      Email Address: <span className="lowercase">{r.emailAddress}</span>
                    </div>
                    <div>Phone Number: {r.phoneNumber}</div>
                    <div>Is Chair: {r.isChair ? "Yes" : "No"}</div>
                  </div>
                ))}
            </div>
            <AccreditationPanel course={row} />
          </div>
        )}
      </ReactModal>
      <div onClick={() => setIsOpen(true)} aria-label="approve" className="cursor-pointer" color="success" style={{ cursor: "pointer" }}>
        <UserGroupIcon className="max-w-[35px]" color="green" />
      </div>
    </>
  );
};

export default NewAccreditation;
