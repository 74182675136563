import React from "react";
import HttpRequest from "../../functions/HttpRequest";
import { useQuery } from "@tanstack/react-query";
import { MaterialReactTable } from "material-react-table";
import NewAccreditation from "./NewAccreditation";
import PanelMembers from "../../components/PanelMembers";
import StartNewAccreditation from "./StartNewAccreditation";

const StartAccreditation = () => {
  const { data, isLoading } = useQuery({
    queryKey: ["ListofProgramme"],
    queryFn: async () => {
      const req = await HttpRequest(`/nuc_programmes/ssf`, {
        method: "GET",
      });
      return req;
    },
  });

  const columns = [
    {
      accessorFn: (row) => `${row?.departmentID?.collegeID?.institutionID?.name}`,
      header: "University",
    },
    {
      accessorFn: (row) => `${row?.departmentID?.collegeID?.name}`,
      header: "College/Faculty",
    },
    {
      accessorFn: (row) => `${row?.departmentID?.name}`,
      header: "Department",
    },
    {
      accessorFn: (row) => `${row?.name}`,
      header: "Programme",
    },
    {
      accessorFn: (row) => <StartNewAccreditation row={row} />,
      header: "Start",
    },
  ];
  return (
    <>
      <div className="mx-auto max-w-7xl px-8 pt-4">
        <h2 className="text-4xl my-5 uppercase">Start Accreditation...</h2>
      </div>
      <div className="mx-auto max-w-12xl px-8 pt-4">
        <div className="grid grid-cols-1 gap-x-8 gap-y-10 py-2">
          {isLoading ? (
            <div>Please wait system loading...</div>
          ) : (
            <div className="TheTable">
              <MaterialReactTable columns={columns} data={data} enableRowNumbers initialState={{ density: "comfortable" }} />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default StartAccreditation;
