import React from "react";
import ClipLoader from "react-spinners/ClipLoader";
import HttpRequest from "../../functions/HttpRequest";
import SwalNotification from "../../functions/SwalNotification";
import is_null from "../../functions/is_null";
import SelectField from "../../components/SelectField";

const DeleteSemeser = () => {
  const [collegeList, setcollegeList] = React.useState(null);
  const [submitting, setSubmitting] = React.useState(false);

  React.useEffect(() => {
    (async () => {
      try {
        const req = await HttpRequest(`/nuc_semester`, {
          method: "GET",
        });
        if (req.type) {
          SwalNotification(req.type, req.message, "CREATE UNIVERSITY");
          return;
        }
        const op = req?.map((r) => ({ value: r?._id, label: `${r?.name} - ${r?.description}` }));
        setcollegeList(op);
      } catch ({ message }) {
        SwalNotification("error", message, "System Error");
      }
    })();
  }, []);
  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      const fd = new FormData(e.target);
      setSubmitting(true);

      const college = fd.get("college");
      if (is_null(college)) {
        throw new Error("Please fill the form properly");
      }
      console.log(100);
      const req = await HttpRequest(`/nuc_semester/${college}`, {
        method: "DELETE",
      });
      SwalNotification(req.type, req.message, "System Error");
      setSubmitting(false);
    } catch ({ message }) {
      SwalNotification("error", message, "System Error");
      setSubmitting(false);
    }
  };
  return (
    <div className="mx-auto max-w-7xl px-8 pt-4">
      <h2 className="text-4xl my-5 uppercase">Delete Accrediation Season...</h2>
      <div className="grid grid-cols-1 gap-x-8 gap-y-10 py-2">
        <form onSubmit={handleSubmit}>
          {collegeList && <SelectField name={"college"} label={"Pick a season"} options={collegeList} />}
          <div className="flex items-center justify-center mb-4">{submitting && <ClipLoader size={35} color={"#36D7B7"} />}</div>
          <button
            type="submit"
            disabled={submitting}
            className="w-full bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded-lg focus:outline-none focus:bg-green-600"
          >
            {submitting ? "Deleting Season..." : "Delete Season"}
          </button>
        </form>
      </div>
    </div>
  );
};

export default DeleteSemeser;
