import CreateDepartment from "./CreateDepartment";
import UpdateDepartment from "./UpdateDepartment";
import DeleteDepartment from "./DeleteDepartment";
import ViewDepartment from "./ViewDepartment";
import ViewDepartmentProgram from "./ViewDepartmentProgram";
import ProposeCreateDepartment from "./ProposeCreateDepartment";
import ProposeUpdateDepartment from "./ProposeUpdateDepartment";
import ProposeDeleteDepartment from "./ProposeDeleteDepartment";
export default {
  CreateDepartment,
  UpdateDepartment,
  DeleteDepartment,
  ViewDepartment,
  ViewDepartmentProgram,
  ProposeCreateDepartment,
  ProposeUpdateDepartment,
  ProposeDeleteDepartment,
};
