import { Dialog, DialogPanel, DialogTitle } from "@headlessui/react";
import { XCircleIcon } from "@heroicons/react/24/solid";

const Modal = ({ isOpen, onClose, title, description, children }) => {
	return (
		<Dialog open={isOpen} onClose={onClose} className="relative z-50">
			{/* Background overlay */}
			<div
				className="fixed inset-0 bg-black opacity-50"
				aria-hidden="true"
			></div>

			{/* Modal Panel */}
			<div className="fixed inset-0 flex items-center justify-center p-4">
				<DialogPanel className="relative max-w-full space-y-4 rounded-lg bg-white p-12 shadow-lg">
					<div className="flex flex-row justify-end items-center absolute top-0 right-0 p-4">
						<XCircleIcon className="h-8 w-8 cursor-pointer" onClick={onClose} />
					</div>
					<DialogTitle className="text-3xl font-bold">{title}</DialogTitle>
					{description && (
						<p className="text-lg text-gray-600">{description}</p>
					)}

					{/* Modal Content (Customizable) */}
					<div>{children}</div>
				</DialogPanel>
			</div>
		</Dialog>
	);
};

export default Modal;
