import React from "react";
import ClipLoader from "react-spinners/ClipLoader";
import TextAreaField from "../../components/TextAreaField";
import SelectField from "../../components/SelectField";
import SwalNotification from "../../functions/SwalNotification";
import HttpRequest from "../../functions/HttpRequest";
import is_null from "../../functions/is_null";
import InputField from "../../components/InputField";

const UpdateSemester = () => {
  const [pickedCollege, setpickedCollege] = React.useState(null);
  const [collegeList, setcollegeList] = React.useState(null);
  const [loadingSave, setloadingSave] = React.useState(false);
  const [dataCollge, setdataCollge] = React.useState(null);

  React.useEffect(() => {
    (async () => {
      try {
        const req = await HttpRequest(`/nuc_semester`, {
          method: "GET",
        });
        if (req.type) {
          SwalNotification(req.type, req.message, "CREATE UNIVERSITY");
          return;
        }
        const op = req?.map((r) => ({ value: r?._id, label: `${r?.name} - ${r?.description}` }));
        setcollegeList(op);
        setdataCollge(req);
      } catch ({ message }) {
        SwalNotification("error", message, "System Error");
      }
    })();
  }, []);
  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      const fd = new FormData(e.target);
      setloadingSave(true);
      const picked = fd.get("picked");
      const name = fd.get("name");
      const description = fd.get("description");
      if (is_null(name) || is_null(description)) {
        throw new Error("Please fill the form properly");
      }

      const req = await HttpRequest(`/nuc_semester/${picked}`, {
        body: JSON.stringify({ name, description }),
        method: "PUT",
      });
      SwalNotification(req.type, req.message, "System Error");
      setloadingSave(false);
    } catch ({ message }) {
      SwalNotification("error", message, "System Error");
      setloadingSave(false);
    }
  };
  //
  return (
    <div className="mx-auto max-w-7xl px-8 pt-4">
      <h2 className="text-4xl my-5 uppercase">Update Accrediation Season...</h2>
      <div className="grid grid-cols-1 gap-x-8 gap-y-10 py-2">
        <form onSubmit={handleSubmit}>
          {collegeList && (
            <SelectField
              name={"picked"}
              label={"Pick a season"}
              options={collegeList}
              setValues={(r) => {
                const { value } = r;
                const filter = dataCollge?.filter((f) => f._id === value).shift();
                setpickedCollege(filter);
              }}
            />
          )}

          <InputField
            label={`Enter Season Year (2023)`}
            placeholder={`Enter Season Year (2023)`}
            required
            name={`name`}
            defaultValue={pickedCollege ? pickedCollege.name : ""}
          />

          <TextAreaField
            label={`Enter Season Description (2023  Season Accreditation)`}
            placeholder={`Enter Season Description (2023  Season Accreditation)`}
            required
            name={`description`}
            defaultValue={pickedCollege ? pickedCollege.description : ""}
          />
          <div className="flex items-center justify-center mb-4">{loadingSave && <ClipLoader size={35} color={"#36D7B7"} />}</div>
          <button
            type="submit"
            disabled={loadingSave}
            className="w-full bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded-lg focus:outline-none focus:bg-green-600"
          >
            {loadingSave ? "Updating Season..." : "Update Season"}
          </button>
        </form>
      </div>
    </div>
  );
};

export default UpdateSemester;
