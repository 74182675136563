import React from "react";
import { useNavigate } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import Cookies from "js-cookie";
import { loginUser } from "../../service/authService";
import { toast } from "react-toastify";
import HttpRequest from "../../functions/HttpRequest";
import InputField from "../../components/InputField";
import SwalNotification from "../../functions/SwalNotification";
import is_null from "../../functions/is_null";

const LoginForm = () => {
  const navigate = useNavigate();
  const [loading, setloading] = React.useState(false);
  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      setloading(true);
      const fd = new FormData(e.target);
      const emailaddress = fd.get("emailaddress");
      const password = fd.get("password");
      if (is_null(emailaddress) || is_null(password)) {
        throw new Error("Please fill the form properly");
      }
      const req = await HttpRequest(`/nuc_staff/login`, {
        body: JSON.stringify({
          emailaddress,
          password,
        }),
        method: "POST",
        headers: { "Content-type": "application/json" },
      });

      if (req?.message === undefined) {
        const { token: data, user } = req;
        if (user !== undefined) {
          const isAuthenticated = await loginUser({ data, user });
          if (isAuthenticated) {
            Cookies.set("Set-Cookie", data);
            SwalNotification("success", "Login Successful", "Success");
            navigate("/dashboard");
          } else {
            toast.error("Login Failed");
          }
        } else {
          toast.error("Login Failed");
        }
      } else {
        toast.error(req?.message);
      }
    } catch ({ message }) {
      toast.error(message);
    } finally {
      setloading(false);
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <InputField required name={"emailaddress"} label={`Email Address`} />
        <InputField required type={"password"} name={"password"} label={`Password`} />
        <div className="flex items-center justify-center mb-4">{loading && <ClipLoader size={35} color={"#36D7B7"} />}</div>
        <button
          type="submit"
          disabled={loading}
          className="w-full bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded-lg focus:outline-none focus:bg-green-600"
        >
          {loading ? "Signing In..." : "Sign In"}
        </button>
      </form>
    </>
  );
};

export default LoginForm;
