import React from "react";
import { Navigate } from "react-router-dom";

const PublicRoute = ({ children, restricted = false }) => {
  const isAuthenticated = !!localStorage.getItem("authToken");

  if (isAuthenticated && restricted) {
    return <Navigate to="/dashboard" />;
  }

  return children;
};

export default PublicRoute;
