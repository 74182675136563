import { SwipeRight, Close } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import React from "react";
import ReactModal from "react-modal";
import SelectField from "./SelectField";
import SwalNotification from "../functions/SwalNotification";
import HttpRequest from "../functions/HttpRequest";
const customStyles = {
  overlay: {
    zIndex: 1000,
  },
  content: {
    top: "50%",
    left: "50%",
    minWidth: "50%",
    right: "auto",
    zIndex: 1001,
    textTransform: "uppercase",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

const AssignSSFBtn = ({ row, section }) => {
  const [IsOpen, SetIsOpen] = React.useState(false);
  const [IsSubmitting, SetIsSubmitting] = React.useState(false);
  const [Exercise, SetExercise] = React.useState(null);
  const _loads = () => SetIsOpen(true);
  const _assign = async (e) => {
    try {
      e.preventDefault();
      SetIsSubmitting(true);
      const req = await HttpRequest("/nuc_programmes/ssf", {
        method: "PUT",
        body: JSON.stringify({
          exerciseID: Exercise?.value,
          programmeID: row?._id,
        }),
      });
      if (req?.type === "error") {
        throw new Error(req?.message);
      }
      SwalNotification(req?.type, req?.message, "CREATE UNIVERSITY");
      SetIsSubmitting(false);
    } catch ({ message }) {
      SwalNotification("error", message, "CREATE UNIVERSITY");
      SetIsSubmitting(false);
    }
  };
  return (
    <>
      <ReactModal style={customStyles} isOpen={IsOpen} contentLabel="Example Modal">
        <div className="grid grid-cols-2 gap-4 gap-y-10 py-2">
          <h2>{row.name} SELF STUDY FORM ALLOCATION</h2>
          <button onClick={() => SetIsOpen(false)} className="text-right">
            <Close color="red" />
          </button>
        </div>
        <hr />
        <form onSubmit={_assign} className="grid grid-cols-1 min-h-80 gap-x-8 gap-y-10 py-1" style={{ maxHeight: "60vh", overflowX: "auto" }}>
          <div className="grid grid-cols-2 gap-4 gap-y-10 py-2">
            <SelectField
              name={"exerciseID"}
              label={"Pick a exercise type"}
              required
              options={section?.map((r) => ({ label: r?.title, value: r?._id }))}
              setValues={SetExercise}
            />
            <button
              type="submit"
              disabled={IsSubmitting}
              className="w-full bg-green-500 max-h-14 hover:bg-green-600 text-white font-bold py-4 mt-5 px-4 rounded-lg focus:outline-none focus:bg-green-600"
            >
              {IsSubmitting ? "Assigning Self Study Form..." : "Assign Self Study Form"}
            </button>
          </div>
        </form>
      </ReactModal>
      <IconButton onClick={_loads} aria-label="approve" className="cursor-pointer" color="success" style={{ cursor: "pointer" }}>
        <SwipeRight color="red" />
      </IconButton>
    </>
  );
};

export default AssignSSFBtn;
