import React from "react";
import Logo from "../../assets/images/logo.png";

const Header = () => {
  return (
    <div className="flex flex-col  mb-8 text-center">
      <div className="flex justify-center items-center">
        <img src={Logo} className="text-center w-40" alt="Logo" />
      </div>
      <p className="text-gray-300 rounded-md bg-black text-lg text-center">NUC ACCREDITATION MANAGEMENT</p>
      <h2 className="text-4xl mb-2 text-green-500 text-center font-bold">ADMINISTRATOR SIGN IN</h2>
    </div>
  );
};

export default Header;
