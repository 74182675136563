import CreateUniversity from "./CreateUniversity";
import UpdateUniversity from "./UpdateUniversity";
import DeleteUniversity from "./DeleteUniversity";
import ViewUniversities from "./ViewUniversity";
import ProposeUniversity from "./ProposeUniversity";
import UniversityColleges from "./UniversityColleges";
import ProposeCreateUniversity from "./ProposeCreateUniversity";

export default {
	CreateUniversity,
	UpdateUniversity,
	DeleteUniversity,
	ViewUniversities,
	ProposeUniversity,
	UniversityColleges,
	ProposeCreateUniversity,
};
