import React, { useState } from "react";
import { MaterialReactTable } from "material-react-table";
import ClipLoader from "react-spinners/ClipLoader";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import HttpRequest from "../../functions/HttpRequest";
import { EyeIcon } from "@heroicons/react/24/solid";
import Modal from "../../components/common/modal";

const ProposeUpdateCollege = () => {
	const [isModalOpen, setModalOpen] = useState(false);
	const [selectedCollege, setSelectedCollege] = useState(null);
	const [rejectReason, setRejectReason] = useState("");
	const [showRejectTextArea, setShowRejectTextArea] = useState(false);

	const queryClient = useQueryClient();

	const openModal = (college) => {
		setSelectedCollege(college);
		setModalOpen(true);
	};

	const closeModal = () => {
		setSelectedCollege(null);
		setModalOpen(false);
	};
	const { data: colleges, isLoading } = useQuery({
		queryKey: ["colleges"],
		queryFn: async () => {
			const res = await HttpRequest(`/ti_pro_colleges`, {
				method: "GET",
			});
			return res;
		},
	});

	const columns = [
		{
			accessorFn: (row) => row?.institutionID?.name,
			header: "University Name",
		},
		{
			accessorKey: "name",
			header: "New College/Faculty Name",
		},
		{
			accessorFn: (row) => row?.old_record?.name,
			header: "Old College/Faculty Name",
		},
		{
			accessorKey: "updatedAt",
			header: "Date & Time",
		},
		{
			accessorFn: (row) => (
				<EyeIcon
					aria-label="approve"
					className="cursor-pointer h-6 w-6"
					onClick={() => openModal(row)}
				/>
			),
			header: "View",
		},
	];

	const handleApprove = useMutation({
		mutationFn: async (college) => {
			const res = await HttpRequest(
				`/ti_pro_colleges/approve-college/${college?._id}`,
				{
					method: "PUT",
					headers: { "Content-type": "application/json" },
				}
			);
			return res;
		},
		onSuccess: () => {
			queryClient.invalidateQueries("colleges");
			closeModal();
		},
	});

	const handleReject = useMutation({
		mutationFn: async ({ collegeId, reason }) => {
			const res = await HttpRequest(`/ti_pro_colleges/reason/reject`, {
				body: JSON.stringify({ collegeId, reason }),
				method: "PUT",
				headers: { "Content-type": "application/json" },
			});
			return res;
		},
		onSuccess: () => {
			queryClient.invalidateQueries("colleges");
			closeModal();
		},
	});

	console.log(colleges);

	return (
		<>
			<div className="mx-auto max-w-7xl px-8 pt-4" style={{ zIndex: -10 }}>
				<h2 className="text-4xl my-5 uppercase">Proposed Update College</h2>
				<div
					className="grid grid-cols-1 gap-x-8 gap-y-10 py-2"
					style={{ zIndex: -10 }}
				>
					<>
						{isLoading ? (
							<ClipLoader size={35} color={"#36D7B7"} />
						) : (
							<div className="TheTable">
								<MaterialReactTable
									columns={columns}
									data={colleges}
									enableRowNumbers
									initialState={{ density: "compact" }}
								/>
							</div>
						)}
					</>
				</div>
			</div>
			<Modal
				isOpen={isModalOpen}
				onClose={closeModal}
				title="Proposed change of College"
			>
				{selectedCollege ? (
					<div>
						<h2 className="text-xl font-bold my-5 leading-4 ">
							{selectedCollege?.institutionID?.name}
						</h2>
						<p className="">
							<span className="text-lg font-bold mr-2">New College:</span>
							{selectedCollege?.name}
						</p>

						<p className="">
							<span className="text-lg font-bold mr-2">Old College:</span>
							{selectedCollege?.old_record?.name}
						</p>
					</div>
				) : (
					<p>No College data available</p>
				)}
				<div className="flex gap-4 my-5">
					<button
						onClick={() => {
							setShowRejectTextArea(true);
							setSelectedCollege(selectedCollege);
						}}
						className="px-4 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600"
					>
						Reject
					</button>
					<button
						onClick={() => handleApprove.mutate(selectedCollege)}
						className="px-4 py-2 bg-green-500 text-white rounded-lg hover:bg-green-600"
					>
						Approve
					</button>
				</div>
			</Modal>

			{showRejectTextArea && (
				<Modal
					isOpen={showRejectTextArea}
					onClose={() => setShowRejectTextArea(false)}
					title="Reject Institution"
				>
					<textarea
						value={rejectReason}
						onChange={(e) => setRejectReason(e.target.value)}
						className="w-full h-20 p-2 border border-gray-300 rounded"
						placeholder="Enter reason for rejection"
					/>
					<div className="flex gap-4 my-5">
						<button
							onClick={() => setShowRejectTextArea(false)}
							className="px-4 py-2 bg-gray-500 text-white rounded-lg hover:bg-gray-600"
						>
							Cancel
						</button>
						<button
							onClick={() =>
								handleReject.mutate({
									institutionId: selectedCollege,
									reason: rejectReason,
								})
							}
							className="px-4 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600"
						>
							Reject
						</button>
					</div>
				</Modal>
			)}
		</>
	);
};

export default ProposeUpdateCollege;
