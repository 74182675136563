import React from "react";
import SelectField from "../../components/SelectField";

const PositiveOption = ({ questionType, setPositive, info }) => {
  const ty = questionType === 3 ? "YES" : questionType === 4 ? "TRUE" : "-";
  const options = info?.map((r) => ({ label: `Question ${r.questionSerial}: ${r.question}`, value: r._id }));
  return (
    <SelectField
      name={"isVideoRequired"}
      label={`Select follow up question when answer is ${ty}`}
      options={options}
      setValues={(e) => {
        setPositive(e?.value);
      }}
    />
  );
};

export default PositiveOption;
