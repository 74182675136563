import React from "react";
import ClipLoader from "react-spinners/ClipLoader";
import HttpRequest from "../../functions/HttpRequest";
import SwalNotification from "../../functions/SwalNotification";
import is_null from "../../functions/is_null";
import SelectField from "../../components/SelectField";

const DeleteCollege = () => {
  const [pickedCollege, setpickedCollege] = React.useState(null);
  const [picked, setpicked] = React.useState(null);
  const [collegeList, setcollegeList] = React.useState(null);
  const [loading, setloading] = React.useState(false);
  const [submitting, setSubmitting] = React.useState(false);
  const [data, setdata] = React.useState(null);
  const [dataCollge, setdataCollge] = React.useState(null);
  React.useEffect(() => {
    (async () => {
      try {
        setloading(true);
        const req = await HttpRequest(`/nuc_institutions/actives`, {
          method: "GET",
        });
        if (req.type) {
          SwalNotification(req.type, req.message, "CREATE UNIVERSITY");
          return;
        }
        const op = req?.map((r) => ({ value: r?._id, label: r?.name }));
        setdata(op);
        setloading(false);
      } catch ({ message }) {
        SwalNotification("error", message, "System Error");
        setloading(false);
      }
    })();
  }, []);
  React.useEffect(() => {
    if (!is_null(picked)) {
      (async () => {
        try {
          const req = await HttpRequest(`/nuc_colleges/university/${picked.value}`, {
            method: "GET",
          });
          if (req.type) {
            SwalNotification(req.type, req.message, "CREATE UNIVERSITY");
            return;
          }
          const op = req?.map((r) => ({ value: r?._id, label: r?.name }));
          setcollegeList(op);
          setdataCollge(req);
        } catch ({ message }) {
          SwalNotification("error", message, "System Error");
          // setloading(false);
        }
      })();
    }
  }, [picked]);
  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      const fd = new FormData(e.target);
      setSubmitting(true);

      const university = fd.get("university");
      const college = fd.get("college");
      if (is_null(university) || is_null(college)) {
        throw new Error("Please fill the form properly");
      }
      console.log(100);
      const req = await HttpRequest(`/nuc_colleges/${college}`, {
        method: "DELETE",
      });
      SwalNotification(req.type, req.message, "System Error");
      setSubmitting(false);
    } catch ({ message }) {
      SwalNotification("error", message, "System Error");
      setSubmitting(false);
    }
  };
  return (
    <div className="mx-auto max-w-7xl px-8 pt-4">
      <h2 className="text-4xl my-5 uppercase">Delete College...</h2>
      <div className="grid grid-cols-1 gap-x-8 gap-y-10 py-2">
        <form onSubmit={handleSubmit}>
          {loading ? (
            <div>Loading...</div>
          ) : (
            <SelectField
              name={"university"}
              label={"Pick a university"}
              options={data}
              setValues={(r) => {
                const { value } = r;
                const filter = data?.filter((f) => f.value === value).shift();
                setpicked(filter);
              }}
            />
          )}
          {collegeList && (
            <SelectField
              name={"college"}
              label={"Pick a college"}
              options={collegeList}
              setValues={(r) => {
                const { value } = r;
                const filter = dataCollge?.filter((f) => f._id === value).shift();
                setpickedCollege(filter);
              }}
            />
          )}
          <div className="flex items-center justify-center mb-4">{submitting && <ClipLoader size={35} color={"#36D7B7"} />}</div>
          <button
            type="submit"
            disabled={submitting}
            className="w-full bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded-lg focus:outline-none focus:bg-green-600"
          >
            {submitting ? "Deleting College..." : "Delete College"}
          </button>
        </form>
      </div>
    </div>
  );
};

export default DeleteCollege;
