import { Add } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import React from "react";
import SwalNotification from "../functions/SwalNotification";
import HttpRequest from "../functions/HttpRequest";

const ReaccreditationSSF = ({ row }) => {
  const [loading, setloading] = React.useState(false);

  const _assign = async (e) => {
    try {
      e.preventDefault();
      setloading(true);
      const req = await HttpRequest("/nuc_programmes/ssf/reaccreditate", {
        method: "PUT",
        body: JSON.stringify({
          programmeID: row?._id,
        }),
      });
      SwalNotification(req?.type, req?.message, "CREATE UNIVERSITY");
      setloading(false);
    } catch ({ message }) {
      SwalNotification("error", message, "CREATE UNIVERSITY");
      setloading(false);
    }
  };
  return (
    <>
      <IconButton disabled={loading} onClick={_assign} aria-label="approve" className="cursor-pointer" color="success" style={{ cursor: "pointer" }}>
        <Add color="green" />
      </IconButton>
    </>
  );
};

export default ReaccreditationSSF;
