import { Fragment, useState } from "react";
import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  Popover,
  PopoverButton,
  PopoverGroup,
  PopoverPanel,
  Tab,
  TabGroup,
  TabList,
  TabPanel,
  TabPanels,
} from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import img from "../assets/images/logo.png";
import { Link } from "react-router-dom";
const navigation = {
  categories: [
    {
      id: "universities",
      name: "University Structure",
      sections: [
        {
          id: "universities_info",
          name: "Manage Universities",
          items: [
            { name: "Create University", href: "create-university" },
            { name: "Update University", href: "update-university" },
            { name: "Delete University", href: "delete-university" },
            { name: "View Universites", href: "view-universities" },
            { name: "University's Colleges", href: "university-colleges" },
            {
              name: "Proposed Create University",
              href: "propose-create-university",
            },
            { name: "Proposed Update University", href: "propose-university" },
          ],
        },
        {
          id: "colleges",
          name: "Manage Colleges/Faculties",
          items: [
            {
              name: "Create College/Faculties",
              href: "create-college",
            },
            {
              name: "Update College/Faculties",
              href: "update-college",
            },
            {
              name: "Delete College/Faculties",
              href: "delete-college",
            },
            { name: "View Colleges/Faculties", href: "view-college" },
            { name: "College's Departments", href: "view-college-department" },
            {
              name: "Propose Create College/Faculties",
              href: "propose-create-college",
            },
            {
              name: "Propose Update College/Faculties",
              href: "propose-update-college",
            },
            {
              name: "Propose Delete College/Faculties",
              href: "propose-delete-college",
            },
          ],
        },
        {
          id: "departments",
          name: "Manage Departments",
          items: [
            { name: "Create Department", href: "create-department" },
            { name: "Update Department", href: "update-department" },
            { name: "Delete Department", href: "delete-department" },
            { name: "View Departments", href: "view-department" },
            {
              name: "Department's Programme",
              href: "department-programme",
            },
            {
              name: "Propose Create Department",
              href: "propose-create-department",
            },
            {
              name: "Propose Update Department",
              href: "propose-update-department",
            },
            {
              name: "Propose Delete Department",
              href: "propose-delete-department",
            },
          ],
        },
        {
          id: "programmes",
          name: "Manage Programmes",
          items: [
            { name: "Create Programme", href: "create-programme" },
            { name: "Update Programme", href: "update-programme" },
            { name: "Delete Programme", href: "delete-programme" },
            { name: "View Programmes", href: "view-programme" },
            {
              name: "Propose Create Programme",
              href: "propose-create-programme",
            },
            {
              name: "Propose Update Programme",
              href: "propose-update-programme",
            },
            {
              name: "Propose Delete Programme",
              href: "propose-delete-programme",
            },
          ],
        },
      ],
    },
    {
      id: "accreditation",
      name: "Self Study Form",
      sections: [
        {
          id: "exercisetype",
          name: "Accreditation Exercise Type",
          items: [
            { name: "Create Exercise Type", href: "create-exercise-type" },
            { name: "Update Exercise Type", href: "update-exercise-type" },
            { name: "Delete Exercise Type", href: "delete-exercise-type" },
            { name: "View Exercise Type", href: "view-exercise-type" },
          ],
        },
        {
          id: "questionsections",
          name: "Manage Question Sections",
          items: [
            { name: "Create Section", href: "create-section" },
            { name: "Update Section", href: "update-section" },
            { name: "Delete Section", href: "delete-section" },
            { name: "View Section", href: "view-section" },
            { name: "Exercise's Sections", href: "exercise-section" },
          ],
        },
        {
          id: "accreditationquestions",
          name: "Manage SSF Questions",
          items: [
            { name: "Create Question", href: "create-question" },
            { name: "Update Question", href: "update-question" },
            { name: "Delete Question", href: "delete-question" },
            { name: "View Question", href: "view-question" },
          ],
        },
        {
          id: "programmeaccreditation",
          name: "Self Study Form (SSF)",
          items: [
            { name: "Assign Self Study Form ", href: "assign-ssf" },
            { name: "View Assigned SSF", href: "assigned-ssf" },
            {
              name: "Change Self Study Form",
              href: "change-ssf",
            },
            {
              name: "Widthdraw Self Study Form",
              href: "widthdraw-ssf",
            },
          ],
        },
      ],
    },
    {
      id: "accreditationsession",
      name: "Accreditation Season",
      sections: [
        {
          id: "programmeaccreditation",
          name: "Manage Accreditation Season",
          items: [
            { name: "Create Season", href: "create-season" },
            { name: "Update Season", href: "update-season" },
            { name: "Delete Season", href: "delete-season" },
            { name: "View Season", href: "view-season" },
          ],
        },
        {
          id: "programmeaccreditation",
          name: "Manage Accreditation",
          items: [
            { name: "Start Accreditation", href: "start-accreditation" },
            { name: "Assign Accreditation Panel", href: "create-accreditation-panel" },
            {
              name: "Accrediation Reports",
              href: "accreditation-notification",
            },
            // {
            //   name: "Re-accreditation Exercise",
            //   href: "reaccreditation-exercise",
            // },
            {
              name: "Start Reaccreditation SSF",
              href: "reaccreditation-exercise",
            },
          ],
        },
        {
          id: "exercisetype",
          name: "Review Accreditation Answers",
          items: [
            { name: "View SSF Answers", href: "view-ssf-answers" },
            { name: "View Accreditation Answers", href: "#" },
            { name: "Publish Answers to Universities", href: "#" },
            { name: "Universities Response to Accreditation", href: "#" },
          ],
        },
        {
          id: "questionsections",
          name: "Accreditation Results",
          items: [
            { name: "View Current Result", href: "#" },
            { name: "View Interim Results", href: "#" },
            { name: "View Accreditation History", href: "#" },
            { name: "Upload Accreditation Results", href: "#" },
          ],
        },
      ],
    },
  ],
  pages: [{ name: "Sign Out", href: "signout" }],
};

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const NavigationBar = () => {
  const [open, setOpen] = useState(false);

  return (
    <div className="bg-white">
      {/* Mobile menu */}
      <Dialog className="relative z-50" open={open} onClose={setOpen}>
        <DialogBackdrop transition className="fixed bg-black bg-opacity-25 transition-opacity duration-300 ease-linear data-[closed]:opacity-0" />
        <div className="zIndexIsIT fixed flex">
          <DialogPanel
            transition
            className="relative flex w-full max-w-xs transform flex-col overflow-y-auto bg-white pb-12 shadow-xl transition duration-300 ease-in-out data-[closed]:-translate-x-full"
          >
            <div className="flex px-4 pb-2 pt-5">
              <button
                type="button"
                className="relative -m-2 inline-flex items-center justify-center rounded-md p-2 text-gray-400"
                onClick={() => setOpen(false)}
              >
                <span className="absolute -inset-0.5" />
                <span className="sr-only">Close menu</span>
                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <div>
              <TabGroup className="mt-2">
                <div className="border-b border-gray-200">
                  <TabList className="-mb-px flex space-x-8 px-4">
                    {navigation.categories.map((category) => (
                      <Tab
                        key={category.name}
                        className={({ selected }) =>
                          classNames(
                            selected ? "border-indigo-600 text-indigo-600" : "border-transparent text-gray-900",
                            "flex-1 whitespace-nowrap border-b-2 px-1 py-4 text-base font-medium"
                          )
                        }
                      >
                        {category.name}
                      </Tab>
                    ))}
                  </TabList>
                </div>
                <TabPanels as={Fragment}>
                  {navigation.categories.map((category) => (
                    <TabPanel key={category.name} className="space-y-10 px-4 pb-8 pt-10">
                      {category.sections.map((section) => (
                        <div key={section.name}>
                          <p id={`${category.id}-${section.id}-heading-mobile`} className="font-medium text-gray-900">
                            {section.name}
                          </p>
                          <ul role="list" aria-labelledby={`${category.id}-${section.id}-heading-mobile`} className="mt-6 flex flex-col space-y-6">
                            {section.items.map((item) => (
                              <li key={item.name} className="flow-root">
                                <Link to={item.href} className="-m-2 block p-2 text-gray-500">
                                  {item.name}
                                </Link>
                              </li>
                            ))}
                          </ul>
                        </div>
                      ))}
                    </TabPanel>
                  ))}
                </TabPanels>
              </TabGroup>
            </div>
            <div className="space-y-6 border-t border-gray-200 px-4 py-6">
              {navigation.pages.map((page) => (
                <div key={page.name} className="flow-root">
                  <Link to={page.href} className="-m-2 block p-2 font-medium text-gray-900">
                    {page.name}
                  </Link>
                </div>
              ))}
            </div>

            <div className="space-y-6 border-t border-gray-200 px-4 py-6">
              <div className="flow-root">
                <a href="#" className="-m-2 block p-2 font-medium text-gray-900">
                  Sign in
                </a>
              </div>
              <div className="flow-root">
                <a href="#" className="-m-2 block p-2 font-medium text-gray-900">
                  Create account
                </a>
              </div>
            </div>

            <div className="border-t border-gray-200 px-4 py-6">
              <a href="#" className="-m-2 flex items-center p-2">
                <img src="https://tailwindui.com/img/flags/flag-canada.svg" alt="" className="block h-auto w-5 flex-shrink-0" />
                <span className="ml-3 block text-base font-medium text-gray-900">CAD</span>
                <span className="sr-only">, change currency</span>
              </a>
            </div>
          </DialogPanel>
        </div>
      </Dialog>

      <header className="relative bg-white">
        <nav aria-label="Top" className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <div className="border-b border-gray-200">
            <div className="flex h-16 items-center">
              <button type="button" className="relative rounded-md bg-white p-2 text-gray-400 lg:hidden" onClick={() => setOpen(true)}>
                <span className="absolute -inset-0.5" />
                <span className="sr-only">Open menu</span>
                <Bars3Icon className="h-6 w-6" aria-hidden="true" />
              </button>
              <div className="ml-4 flex lg:ml-0">
                <a href="#">
                  <span className="sr-only">Your Company</span>
                  <img className="h-8 w-auto" src={img} alt="" />
                </a>
              </div>

              <div className="ml-auto flex items-center">
                {/* Flyout menus */}
                <PopoverGroup className="hidden lg:ml-8 lg:block lg:self-stretch">
                  <div className="flex h-full space-x-8">
                    {navigation.categories.map((category) => (
                      <Popover key={category.name} className="flex">
                        {({ open }) => (
                          <>
                            <div className="relative flex">
                              <PopoverButton
                                className={classNames(
                                  open ? "border-indigo-600 text-indigo-600" : "border-transparent text-gray-700 hover:text-gray-800",
                                  "relative  -mb-px flex items-center border-b-2 pt-px text-sm font-medium transition-colors duration-200 ease-out"
                                )}
                              >
                                {category.name}
                              </PopoverButton>
                            </div>

                            <PopoverPanel
                              transition
                              className="absolute inset-x-0 top-full text-sm text-gray-500 transition data-[closed]:opacity-0 data-[enter]:duration-200 data-[leave]:duration-150 data-[enter]:ease-out data-[leave]:ease-in"
                            >
                              {/* Presentational element used to render the bottom shadow, if we put the shadow on the actual panel it pokes out the top, so we use this shorter element to hide the top of the shadow */}
                              <div className="absolute inset-0 top-1/2 bg-white shadow" aria-hidden="true" />

                              <div className="relative bg-white">
                                <div className="mx-auto max-w-7xl px-8">
                                  <div className="grid grid-cols-1 gap-x-8 gap-y-10 py-16">
                                    <div className="row-start-1 grid grid-cols-4 gap-x-8 gap-y-8 text-sm">
                                      {category.sections.map((section) => (
                                        <div key={section.name}>
                                          <p id={`${section.name}-heading`} className="font-medium text-gray-900">
                                            {section.name}
                                          </p>
                                          <ul role="list" aria-labelledby={`${section.name}-heading`} className="mt-6 space-y-6 sm:mt-4 sm:space-y-4">
                                            {section.items.map((item) => (
                                              <li key={item.name} className="flex">
                                                <a href={item.href} className="hover:text-gray-800">
                                                  {item.name}
                                                </a>
                                              </li>
                                            ))}
                                          </ul>
                                        </div>
                                      ))}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </PopoverPanel>
                          </>
                        )}
                      </Popover>
                    ))}
                    <span className="h-6 w-px bg-gray-200" aria-hidden="true" />
                    {navigation.pages.map((page) => (
                      <a key={page.name} href={page.href} className="flex items-center text-sm font-medium text-gray-700 hover:text-gray-800">
                        {page.name}
                      </a>
                    ))}
                  </div>
                </PopoverGroup>
              </div>
            </div>
          </div>
        </nav>
      </header>
    </div>
  );
};

export default NavigationBar;
